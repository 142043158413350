export interface CategoryType {
  [language: string]: string
}

export const categories = {
  "Activity": {en: 'Activity', fi: 'Aktiviteetti'},
  "African": {en: 'African', fi: 'Afrikkalainen'},
  "American": {en: 'American', fi: 'Amerikkalainen'},
  "Asian": {en: 'Asian', fi: 'Aasialainen'},
  "Bakery": {en: 'Bakery', fi: 'Leipomo'},
  "Bar": {en: 'Bar', fi: 'Baari'},
  // "Barber shop": {en: 'Barber shop', fi: 'Parturi'},
  "BBQ": {en: 'BBQ', fi: 'BBQ'},
  // "Beauty salon": {en: 'Beauty salon', fi: 'Kauneushoito'},
  "Beer": {en: 'Beer', fi: 'Olut'},
  "Bistro": {en: 'Bistro', fi: 'Bistro'},
  "Breakfast": {en: 'Breakfast', fi: 'Aamiainen'},
  "Brewery": {en: 'Brewery', fi: 'Panimo'},
  "British": {en: 'British', fi: 'Brittiläinen'},
  "Burger": {en: 'Burger', fi: 'Burgeri'},
  "Cafe": {en: 'Cafe', fi: 'Kahvila'},
  "Chinese": {en: 'Chinese', fi: 'Kiinalainen'},
  "Cocktail": {en: 'Cocktail', fi: 'Cocktail'},
  "Cuban": {en: 'Cuban', fi: 'Kuubalainen'},
  "Deli": {en: 'Deli', fi: 'Deli'},
  "Dessert": {en: 'Dessert', fi: 'Jälkiruoka'},
  "Dinner": {en: 'Dinner', fi: 'Illallinen'},
  "European": {en: 'European', fi: 'Eurooppalainen'},
  "Fastfood": {en: 'Fastfood', fi: 'Pikaruoka'},
  "Fine dining": {en: 'Fine dining', fi: 'Fine dining'},
  "Finnish": {en: 'Finnish', fi: 'Suomalainen'},
  "French": {en: 'French', fi: 'Ranskalainen'},
  "Gastro": {en: 'Gastro', fi: 'Gastro'},
  "Georgian": {en: 'Georgian', fi: 'Georgialainen'},
  "German": {en: 'German', fi: 'Saksalainen'},
  "Gin": {en: 'Gin', fi: 'Gin'},
  "Greek": {en: 'Greek', fi: 'Kreikkalainen'},
  "Grill": {en: 'Grill', fi: 'Grilli'},
  "Gym": {en: 'Gym', fi: 'Kuntosali'},
  // "Hair salon": {en: 'Hair salon', fi: 'Kampaamo'},
  "Healthy": {en: 'Healthy', fi: 'Terveellinen'},
  "Indian": {en: 'Indian', fi: 'Intialainen'},
  "Irish": {en: 'Irish', fi: 'Irlantilainen'},
  "Italian": {en: 'Italian', fi: 'Italialainen'},
  "Japanese": {en: 'Japanese', fi: 'Japanilainen'},
  "Juice Bar": {en: 'Juice Bar', fi: 'Mehubaari'},
  "Karaoke": {en: 'Karaoke', fi: 'Karaoke'},
  "Kebab": {en: 'Kebab', fi: 'Kebab'},
  "Korean": {en: 'Korean', fi: 'Korealainen'},
  "Lebanese": {en: 'Lebanese', fi: 'Libanonilainen'},
  "Leisure": {en: 'Leisure', fi: 'Vapaa-aika'},
  "Live music": {en: 'Live music', fi: 'Live-musiikki'},
  "Lunch": {en: 'Lunch', fi: 'Lounas'},
  // "Massage": {en: 'Massage', fi: 'Hieronta'},
  "Mediterranean": {en: 'Mediterranean', fi: 'Välimerellinen'},
  "Mexican": {en: 'Mexican', fi: 'Meksikolainen'},
  "Middle-East": {en: 'Middle-East', fi: 'Lähi-itä'},
  // "Nail salon": {en: 'Nail salon', fi: 'Kynsihoito'},
  "Nightclub": {en: 'Nightclub', fi: 'Yökerho'},
  "Pizza": {en: 'Pizza', fi: 'Pizza'},
  "Pub": {en: 'Pub', fi: 'Pub'},
  "Raw food": {en: 'Raw food', fi: 'Raakaruoka'},
  "Restaurant": {en: 'Restaurant', fi: 'Ravintola'},
  "Russian": {en: 'Russian', fi: 'Venäläinen'},
  "Salad": {en: 'Salad', fi: 'Salaatti'},
  "Scandinavian": {en: 'Scandinavian', fi: 'Pohjoismainen'},
  "Seafood": {en: 'Seafood', fi: 'Merenelävät'},
  "Soups": {en: 'Soups', fi: 'Keitot'},
  "Spa": {en: 'Spa', fi: 'Kylpylä'},
  "Spanish": {en: 'Spanish', fi: 'Espanjainen'},
  "Sportbar": {en: 'Sportbar', fi: 'Sporttibaari'},
  "Steakhouse": {en: 'Steakhouse', fi: 'Pihviravintola'},
  "Street Food": {en: 'Street Food', fi: 'Katuruoka'},
  "Sushi": {en: 'Sushi', fi: 'Sushi'},
  "Swedish": {en: 'Swedish', fi: 'Ruotsalainen'},
  "Terrace": {en: 'Terrace', fi: 'Terassi'},
  "Tequila": {en: 'Tequila', fi: 'Tequila'},
  "Thai": {en: 'Thai', fi: 'Thaimaalainen'},
  "Turkish": {en: 'Turkish', fi: 'Turkkilainen'},
  "Vegan": {en: 'Vegan', fi: 'Vegan'},
  "Vegetarian": {en: 'Vegetarian', fi: 'Kasvisruoka'},
  "Wellbeing": {en: 'Wellbeing', fi: 'Hyvinvointi'},
  "Wine": {en: 'Wine', fi: 'Viini'},
  "Wings": {en: 'Wings', fi: 'Wings'},
  "Events": {en: 'Events', fi: 'Tapahtumat'},
}