import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { observer } from "mobx-react"
import moment from "moment"
import { useState } from "react"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Modal from 'react-bootstrap/Modal'
import Row from "react-bootstrap/Row"
import { useTranslation } from "react-i18next"
import { IVenueReferralCode, IVenueStatus, MEMBER_STATUS_PAYMENT_VALIDITY_TYPE, VENUE_STATUS_THEME, VENUE_STATUS_TYPE } from "../models/MemberStore"
import { colors } from "../theme/colors"
import { FormControl, FormGroup } from "react-bootstrap"
import { centsToFullCurrencyUnits } from "../utils"

export interface MemebershipsSummaryCardProps {
  onEdit: () => void
  memberStatus: IVenueStatus
  referralCode?: IVenueReferralCode
  onGenerateCode?: (customCode?: string) => void
  onRefetchVenueCards?: () => void
}

const ChangeInvitationCodeModal = ({
  showChangeInvitationCodeModal,
  setShowChangeInvitationCodeModal,
  onGenerateCode,
  referralCode,
  onRefetchVenueCards
}:{
  showChangeInvitationCodeModal: boolean
  setShowChangeInvitationCodeModal: (show: boolean) => void
  onGenerateCode?: (customCode?: string) => void
  referralCode?: IVenueReferralCode
  onRefetchVenueCards?: () => void
}) => {
  const [showCustomCodeInput, setShowCustomCodeInput] = useState(false);
  const [customCode, setCustomCode] = useState('');
const [customCodeError, setCustomCodeError] = useState('');
const [isSubmitting, setIsSubmitting] = useState(false);
const {t} = useTranslation()

const handleCustomCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
  setCustomCode(value);
  if (value.length < 6) {
    setCustomCodeError(t("manageMembersCardScreen.modals.changeInvitationCode.customCodeError"));
  } else {
    setCustomCodeError('');
  }
};
  
const handleSetCustomCode = async () => {
  setIsSubmitting(true);
  if (customCode.length >= 6) {
    try {
      if (onGenerateCode) {
     const result =   await onGenerateCode(customCode);
    

     if (Boolean(result)) {
      setShowChangeInvitationCodeModal(false);
      setShowCustomCodeInput(false);
      setCustomCode('');
     } else {
        setCustomCodeError(t("manageMembersCardScreen.modals.changeInvitationCode.apiError"));
        if (onRefetchVenueCards) {
          await onRefetchVenueCards();
        }
      }
    }
   
    } catch (error) {
      console.log('onGenerateCode error', error)
     
    }
  }
  setIsSubmitting(false);
};

const handleAutoCodeGeneration = async () => {
  setIsSubmitting(true);
  try {
    if (onGenerateCode) {
     const result = await onGenerateCode();

      if (Boolean(result)) {
        setShowChangeInvitationCodeModal(false);
        setShowCustomCodeInput(false);
        setCustomCode('');
       } else {
        setCustomCodeError(t("manageMembersCardScreen.modals.changeInvitationCode.apiError"));
       }
    }
  } catch (error) {
    setCustomCodeError(t("manageMembersCardScreen.modals.changeInvitationCode.apiError"));
  }
  setIsSubmitting(false);
}

return <Modal show={showChangeInvitationCodeModal} centered onHide={() => {
  setShowChangeInvitationCodeModal(false);
  setShowCustomCodeInput(false);
  setCustomCode('');
  setCustomCodeError('');
}}>
    <Modal.Header >
            <Modal.Title style={{
            margin: '0 auto'
          }}>{t("manageMembersCardScreen.modals.changeInvitationCode.title")}</Modal.Title>
          </Modal.Header>
  <Modal.Body>
    <div className="text-center mb-3">
      {t("manageMembersCardScreen.modals.changeInvitationCode.currentCode")}
      <div className="font-weight-bold">{referralCode?.code || '-'}</div>
    </div>
    <div className="text-center mb-3">
      {t("manageMembersCardScreen.modals.changeInvitationCode.description")}
    </div>

    <div style={{
      textAlign: 'center',
      color: colors.red,
      fontSize: '14px',
      marginBottom: 10
    }}>
    {customCodeError}
    </div>
    {showCustomCodeInput && (
      <FormGroup controlId="customCode">
        <FormControl
          type="text"
          value={customCode}
          onChange={handleCustomCodeChange}
          placeholder={t("manageMembersCardScreen.modals.changeInvitationCode.customCodePlaceholder")}
       
        />
      </FormGroup>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="outline-primary" onClick={() => {
      setShowChangeInvitationCodeModal(false);
      setShowCustomCodeInput(false);
      setCustomCode('');
      setCustomCodeError('');
    }}>
      {t("manageMembersCardScreen.modals.changeInvitationCode.cancel")}
    </Button>
    {!showCustomCodeInput && (
      <Button variant="primary" onClick={handleAutoCodeGeneration} disabled={isSubmitting}>
        {t("manageMembersCardScreen.modals.changeInvitationCode.confirm")}
      </Button>
    )}
    {showCustomCodeInput && (
      <Button variant="primary" onClick={handleSetCustomCode} disabled={customCode.length < 6 || isSubmitting}>
        {t("manageMembersCardScreen.modals.changeInvitationCode.setCustomCode")}
      </Button>
    )}
  </Modal.Footer>
  <div className="text-center pb-3">
    <Button
      variant="link"
      onClick={() => {
        setShowCustomCodeInput(!showCustomCodeInput);
        setCustomCodeError('');
      }}
    >
      {showCustomCodeInput
        ? t("manageMembersCardScreen.modals.changeInvitationCode.autoCodeGeneration")
        : t("manageMembersCardScreen.modals.changeInvitationCode.customCode")}
    </Button>
  </div>
</Modal>
}

export const MemebershipsSummaryCard = observer((props: MemebershipsSummaryCardProps) => {
  const [showChangeInvitationCodeModal, setShowChangeInvitationCodeModal] = useState(false);
  const {memberStatus, onEdit} = props

  const {t} = useTranslation()






  const statusThemeColor = () => {
    if (memberStatus.style?.theme === VENUE_STATUS_THEME.GOLD) {
      return t("manageMembersCardScreen.gold")
    } else if (memberStatus.style?.theme === VENUE_STATUS_THEME.BLACK) {
      return t("manageMembersCardScreen.black")
    } else {
      return ''
    }
  }


  const getValidityInMonths = (days: number | undefined): string => {
    if (!days) {
      return '';
    }
    
    switch(days) {
      case 30:
        return `1 ${t("manageMembersCardScreen.month")}`
      case 90:
        return `3 ${t("manageMembersCardScreen.months")}`
      case 180:
          return `6 ${t("manageMembersCardScreen.months")}`
      case 365:
        return `12 ${t("manageMembersCardScreen.months")}`
      case 730:
        return `24 ${t("manageMembersCardScreen.months")}`
      default:
        return ''
    }
  }

  const statusPrice = memberStatus.type === VENUE_STATUS_TYPE.SOLD ? `${centsToFullCurrencyUnits(memberStatus.payments?.price?.amount)}€` : t("manageMembersCardScreen.free")
  const getDateRange = (date1: string | undefined, date2: string | undefined): string => {
    if (!date1 || !date2) {
      return '';
    }
    return `${moment(new Date(date1)).format('YYYY-MM-DD')} - ${moment(new Date(date2)).format('YYYY-MM-DD')}`
  };

  const validity = memberStatus.payments.type === MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FIXED 
    ? getDateRange(memberStatus.payments.validFrom, memberStatus.payments.validThrough) 
    : getValidityInMonths(memberStatus.payments.duration)
  
  return (
    <Card className="mb-3 bg-white">
      <ChangeInvitationCodeModal 
        showChangeInvitationCodeModal={showChangeInvitationCodeModal}
        setShowChangeInvitationCodeModal={setShowChangeInvitationCodeModal}
        onGenerateCode={props.onGenerateCode}
        onRefetchVenueCards={props.onRefetchVenueCards}
        referralCode={props.referralCode}
      />
        <Card.Body className="p-2 m-0">
          <Container>
            <Row>
              <Col className="col-8 py-2 d-flex align-items-center justify-content-start">
                <div>
                  <h5
                    className="pl-1 m-0 font-heading font-weight-bold">{memberStatus?.localeTitle}</h5>
                  <p className="pl-1 m-0 mt-1">{memberStatus?.localeShortDescription}</p>
                  {/* TODO: Use a link once we know what that link is */}
                  {/* {membership.description === t("manageMembersCardScreen.availableForPurchase") &&
                    <a target="_blank" className="font-size-small d-block mt-1 mb-1" rel="noopener noreferrer" href="">
                      <FontAwesomeIcon className={"mr-2"} icon={["fal", "link"]}/>{t("manageMembersCardScreen.linkToCard")}
                    </a>
                  } */}

                  <p className="pl-1 m-0 mt-1 text-primary d-inline-block font-size-small">{t("manageMembersCardScreen.price")}: {statusPrice}</p>
                  {/* {statusThemeColor() &&
                    <p className="ml-5 m-0 mt-1 d-inline-block font-size-small">{t("manageMembersCardScreen.color")}: {statusThemeColor()}</p>
                  } */}
                    {memberStatus.type === VENUE_STATUS_TYPE.GIVEN &&
                            <p className="ml-5 m-0 mt-1 d-inline-block font-size-small">{t("manageMembersCardScreen.invitationCode")}: {props.referralCode?.code || '-'}  <FontAwesomeIcon onClick={() => setShowChangeInvitationCodeModal(true)} className="m-0 ml-2" style={{
                              color: colors.primaryText,
                              cursor: 'pointer'
                            }}
                              icon={["fal", "undo"]}/></p>
                      }
           
                  {validity &&
                    <p className="text-green ml-5 m-0 mt-1 d-inline-block font-size-small">{t("manageMembersCardScreen.valid")}: {validity}</p>
                  }
                </div>
              </Col>
              <Col className="col-2 d-flex align-items-center justify-content-end">
                {memberStatus?.images?.mainImage &&
                  <img 
                    alt="membershipCard"
                    src={memberStatus?.images?.mainImage} 
                    style={{width: 100, height: 60, objectFit: 'cover', borderRadius: '5px'}}/>
                }
              </Col>

              {/* <Col
                className="col-2 py-3 d-flex align-items-center justify-content-center bg-white rounded">
                <div className="text-center">
                </div>
              </Col> */}
              
              <Col className="col-2 py-2 d-flex align-items-center justify-content-end">
                <Button
                  variant={"transparent"}
                  onClick={onEdit}
                >
                  <FontAwesomeIcon className="m-0 mr-2" icon={["fal", "pen-to-square"]}/>
                  {t("manageMembersCardScreen.edit")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Body>
    </Card>
  )
})
