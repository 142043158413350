import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, Form, Formik } from 'formik'
import { inject, observer } from "mobx-react"
import moment from "moment-timezone"
import React, { useState } from "react"
import { Container, Form as BootstrapForm, InputGroup } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Card from 'react-bootstrap/Card'
import Col from "react-bootstrap/Col"
import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import Modal from 'react-bootstrap/Modal'
import Row from "react-bootstrap/Row"
import DatePicker from "react-datepicker"
import { withTranslation, WithTranslation } from "react-i18next"
import Skeleton from 'react-loading-skeleton'
import * as Yup from 'yup'
import { MerchantAdminApi } from "../../Api"
import AlertMessage from "../../components/AlertMessage"
import ImageUploadModal from "../../components/ImageUploadModal"
import i18n from "../../i18n"
import { MembersStatusesModifyLocation } from "../../Locations"
import { IMemberStore, MEMBER_STATUS_PAYMENT_VALIDITY_TYPE, VENUE_STATUS_THEME, VENUE_STATUS_TYPE } from "../../models/MemberStore"
import { IUserSessionStore } from "../../models/UserSessionStore"
import { centsToFullCurrencyUnits, fullCurrencyUnitsToCents, urlToFile } from "../../utils"
import { STATUS_TYPE } from "../ManageMemberStatusesScreen"
import "./CreateUpdateMemberStatusScreen.scss"
import { getMembershipStatusCountrySpecificFields } from "../../models/utils/ countrySpecificFields"
import { FormikTitleField } from "../../components/Formik/FormikTitleField"
import { FormikDescriptionField } from "../../components/Formik/FormikDescriptionField"

const MODIFY_PRODUCT_ERROR = "modifyProductError"
const CREATE_UPDATE_PRODUCT_ERROR = "createUpdateProductError"
export interface ProductModifyScreenProps extends WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  memberStore?: IMemberStore
  history?: any
}
const getVenueIDFromURL = () => {
  const urlVars = window.location.pathname.split('/')
  if (urlVars.length >= 5 && urlVars[3] === 'venue') {
    return urlVars[4]
  } else {
    return ''
  }
}

const CreateUpdateMemberStatusScreen = (props: ProductModifyScreenProps) => {
  const [croppedProductPhoto, setCroppedProductPhoto] = useState('')
  const [showHideProductModal, setShowHideProductModal] = useState(false);
  const [showUploadStatusPhotoModal, setShowUploadStatusPhotoModal] = useState(false);
  const [showGoBackModal, setShowGoBackModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);

  const getStatusIDFromURL = () => {
    const urlVars = window.location.pathname.split('/')
    return urlVars[urlVars.length - 1]
  }

  const getStatusTypeFromURL = () => {
    const urlVars = window.location.pathname.split('/')
    if (urlVars.length >= 5 && urlVars[5] === 'statusType') {
      return urlVars[6]
    } else {
      return ''
    }
  }

  const selectedStatusPaymentType = getStatusTypeFromURL()
  const selectedStatusIdId = getStatusIDFromURL()
  const selectedStatus = props.memberStore?.statuses?.find(status => status.id === selectedStatusIdId)
  const [uploadedImage, setUploadedImage] = useState(selectedStatus?.images?.mainImage || "");

  const {t, userSessionStore, memberStore, history, api} = props



  const venueId = getVenueIDFromURL()
  const selectedVenue = userSessionStore?.currentOrganization?.venues.find(venue => venue.id === venueId)


  const countrySpecificFields = getMembershipStatusCountrySpecificFields({venueCountryName: selectedVenue?.country || ""})


  const titleAdditionalCountryField = countrySpecificFields.find(field => field.fieldName === 'title')
  const descriptionAdditionalCountryField = countrySpecificFields.find(field => field.fieldName === 'description')


  const validationSchema = Yup.object().shape({
    id: Yup.string().required(),
    restaurantId: Yup.string().required(t("fieldRequired")),
    text: Yup.object({ 
      active: Yup.object().shape({
        en: Yup.object({ 
          title: Yup.string().required(t("fieldRequired")),
          shortDescription: Yup.string()
        }),
        ...Object.fromEntries(
          countrySpecificFields.map(field => [
            field.countryCode,
            Yup.object({
              title: Yup.string().required(t("fieldRequired")),
              shortDescription: Yup.string()
            })
          ])
        )
      }),
      passive: Yup.object().shape({
        en: Yup.object({
          title: Yup.string().required(t("fieldRequired")),
          description: Yup.string().when("type", {
            is: () => selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD,
            then: Yup.string().required(t("fieldRequired"))
          })
        }),
        ...Object.fromEntries(
          countrySpecificFields.map(field => [
            field.countryCode,
            Yup.object({
              title: Yup.string().required(t("fieldRequired")),
              description: Yup.string().when("type", {
                is: () => selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD,
                then: Yup.string().required(t("fieldRequired"))
              })
            })
          ])
        )
      })
    }),
    type: Yup.string().required(t("fieldRequired")),
    payments: Yup.object({
      type: Yup.string().when({
        is: () => selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD,
        then: Yup.string().required(t("fieldRequired"))
      }),
      price: Yup.object({
        amount: Yup.number().when({
          is: () => selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD,
          then: Yup.number().required(t("fieldRequired")).test(
            'Is positive?', 
            'ERROR: The number must be greater than 0!', 
            (value) => (value || 0) > 0
          )
        }),
        currency: Yup.string().when({
          is: () => selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD,
          then: Yup.string().required(t("fieldRequired"))
        }),
      }),
      vatPercentage: Yup.number().when({
        is: () => selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD,
        then: Yup.number().required(t("fieldRequired"))
      }),
      validFrom: Yup.string().when("type", {
        is: (value: string) =>
          value === MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FIXED && selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD
        ,
        then: Yup.string().required(t("fieldRequired"))
      }),
      validThrough: Yup.string().when("type", {
        is: (value: string) =>
          value === MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FIXED && selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD
        ,
        then: Yup.string().required(t("fieldRequired"))
      }),
      duration: Yup.number().when("type", {
        is: MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FLEXIBLE,
        then: Yup.number().required(t("fieldRequired"))
      }),
    }),
    style: Yup.object({
      theme: Yup.string().required(t("fieldRequired")),
    }),
    images: Yup.object({              
      mainImage: Yup.string(),
    }),
    flags: Yup.object({
      grantEntrance: Yup.string().required(t("fieldRequired")),
      grantCloakroom: Yup.string().required(t("fieldRequired")),
      showExternalIdentifier: Yup.string()
    }),
    visible: Yup.boolean().required(t("fieldRequired"))
  });



  const paymentsInitValueNew = selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD ? {
    type: MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FIXED,
    price: {
      amount: 100,
      currency: "EUR"
    },
    vatPercentage: 10.0,
    validFrom: undefined,
    validThrough: undefined,
    duration: undefined
  }: {}

  const paymentsInitValueExistant = selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD ? {
    type: selectedStatus?.payments?.type || MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FIXED,
    price: {
      amount: selectedStatus?.payments.price?.amount ? centsToFullCurrencyUnits(selectedStatus?.payments.price?.amount) : 100,
      currency: "EUR"
    },
    vatPercentage: selectedStatus?.payments?.vatPercentage || 10.0,
    validFrom: selectedStatus?.payments?.validFrom,
    validThrough: selectedStatus?.payments?.validThrough,
    duration: selectedStatus?.payments?.duration
  }: {}

  const initValues = selectedStatus ? {
    id: selectedStatus.id,
    restaurantId: venueId,
    text: {
      active: {
        en: {
          title: selectedStatus.text?.get("active")?.get("en")?.title || "",
          shortDescription: selectedStatus.text?.get("active")?.get("en")?.shortDescription || "",
        },
        ...Object.fromEntries(
          countrySpecificFields.map(field => [
            field.countryCode,
            {
              title: selectedStatus.text?.get("active")?.get(field.countryCode)?.title || "",
              shortDescription: selectedStatus.text?.get("active")?.get(field.countryCode)?.shortDescription || "",
            }
          ])
        )
      },
      passive: { 
        en: {
          title: selectedStatus.text?.get("passive")?.get("en")?.title || selectedStatus.text?.get("active")?.get("en")?.title || "",
          description: selectedStatus.text?.get("passive")?.get("en")?.description || selectedStatus.text?.get("active")?.get("en")?.description || "",
        },
        ...Object.fromEntries(
          countrySpecificFields.map(field => [
            field.countryCode,
            {
              title: selectedStatus.text?.get("passive")?.get(field.countryCode)?.title || selectedStatus.text?.get("active")?.get(field.countryCode)?.title || "",
              description: selectedStatus.text?.get("passive")?.get(field.countryCode)?.description || selectedStatus.text?.get("active")?.get(field.countryCode)?.description || "",
            }
          ])
        )
      }
    },
    type: selectedStatusPaymentType,
    payments: paymentsInitValueExistant,
    style: { 
      theme: selectedStatus.style.theme || VENUE_STATUS_THEME.GOLD
    },
    images: {              
      mainImage: selectedStatus.images?.mainImage || ""
    },
    flags: {
      grantEntrance: selectedStatus.flags.grantEntrance ? "true" : "false",
      grantCloakroom: selectedStatus.flags.grantCloakroom ? "true" : "false",
      showExternalIdentifier: selectedStatus.flags.showExternalIdentifier ? "true" : "false"
    },
    visible: selectedStatus?.visible
  } : {
    id: "NEW__",
    restaurantId: venueId,
    text: {
      active: {
        en: {
          title: "",
          shortDescription: ""
        },
        ...Object.fromEntries(
          countrySpecificFields.map(field => [
            field.countryCode,
            {
              title: "",
              shortDescription: ""
            }
          ])
        )
      },
      passive: { 
        en: {
          title: "",
          description: ""
        },
        ...Object.fromEntries(
          countrySpecificFields.map(field => [
            field.countryCode,
            {
              title: "",
              description: ""
            }
          ])
        )
      }
    },
    type: selectedStatusPaymentType,
    payments: paymentsInitValueNew,
    style: { 
      theme: VENUE_STATUS_THEME.GOLD
    },
    images: {              
      mainImage: ""
    },
    flags: {
      grantEntrance: "true",
      grantCloakroom: "true",
      showExternalIdentifier: "false"
    },
    visible: true
  }


  const handleSaveAndPublish = async (statuses: any[], newStatus: boolean) => {
    const response = await api!.postStatuses(statuses, undefined, CREATE_UPDATE_PRODUCT_ERROR)
    if (response && response.statuses.length > 0) {
      if (newStatus) {
        memberStore?.addStatus(response.statuses[0])
      } else {
        memberStore?.updateStatus(response.statuses[0])
      }
      history.push(MembersStatusesModifyLocation.toUrl({
        ...selectedVenue?.urlFields(),
        statusType: response.statuses[0].type
      }))
    }
    await props.memberStore?.fetchInvitationCodes()
  }

  const IMAGE_UPLOAD_STATUS = {
    UPLOADING: "UPLOADING",
    ERROR: "ERROR"
  }

  const handleUploadImage = async (imageBlob: any) => {
    setUploadedImage(IMAGE_UPLOAD_STATUS.UPLOADING)
    const imageFile = await urlToFile(imageBlob, 'image.jpeg', 'image/jpeg')
    const imageURL = await api!.uploadImage('venue/product/main', imageFile)
    if (imageURL) {
      setUploadedImage(imageURL)
    } else {
      setUploadedImage(IMAGE_UPLOAD_STATUS.ERROR)
    }
  }

  const MainImage = () => {
    if (uploadedImage === "") {
      return <Card className="croppedCover"/>
    } else if (uploadedImage === IMAGE_UPLOAD_STATUS.UPLOADING) {
      return <Skeleton height={198} width={330}/>
    } else if (uploadedImage === IMAGE_UPLOAD_STATUS.ERROR) {
      return <Card className="croppedCover">
        <div className="alert alert-danger mt-5" role="alert">
          {t('manageVenuesScreen.imageUploadFailed')}
        </div>
      </Card>
    } else {
      return <img className="croppedCover" src={uploadedImage} alt="logo"/>
    }
  }

  const onBackToVenues = (isFormEdited: boolean) => {
    if (isFormEdited)
      setShowGoBackModal(true)
    else
      history.push(MembersStatusesModifyLocation.toUrl({
        ...selectedVenue?.urlFields(),
        statusType: selectedStatusPaymentType
      }))
  }

  

  
  return (
    <Container>
      <AlertMessage source={MODIFY_PRODUCT_ERROR} />

      <Modal show={showGoBackModal} centered onHide={() => {
        setShowGoBackModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{t("cardModifyScreen.goBackPopup.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("cardModifyScreen.goBackPopup.description")}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => {
            setShowGoBackModal(false)
          }}>
            {t("cardModifyScreen.goBackPopup.cancel")}
          </Button>
          <Button variant="primary" onClick={() => {
            history.push(MembersStatusesModifyLocation.toUrl(selectedVenue?.urlFields()))
          }}>
            {t("cardModifyScreen.goBackPopup.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          const paymentsData = selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD ? {
            ...values.payments,
              price: {
                ...values.payments.price,
                amount: fullCurrencyUnitsToCents(values.payments?.price?.amount)
              }
          } : {}

          const statusData = {
            ...values,
            images: {           
              mainImage: uploadedImage || null
            },
            payments: paymentsData
          }

          const isNewStatus = values.id === 'NEW__'
          handleSaveAndPublish([statusData], isNewStatus)
        }}
      >
        {({ errors, touched, values, dirty, handleChange, resetForm, setFieldValue }) => (
          <Form>
            <Modal show={showRevertModal} centered onHide={() => {
              setShowRevertModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("manageVenuesScreen.revertChangesPopup.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("manageVenuesScreen.revertChangesPopup.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  resetForm()
                  setUploadedImage(selectedStatus?.images?.mainImage || "")
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.confirm")}
                </Button>
              </Modal.Footer>
            </Modal>
            <Row>
              <Col md={4}>
                <Button 
                  variant="secondary" 
                  className="font-weight-bold" 
                  onClick={() => onBackToVenues(dirty)}
                  >
                  <FontAwesomeIcon className="mr-2" icon={["fas", "reply-all"]}/>
                    {t("cardModifyScreen.backToVenues")}
                </Button>
              </Col>
              <Col md={8}>
                <div className="float-right">
                  <Button variant="outline-primary font-weight-bold" className="mr-3" onClick={() => setShowRevertModal(true)}>
                    <FontAwesomeIcon className="mr-2" icon={["fas", "trash-alt"]}/>
                      {t("manageVenuesScreen.revert")}
                  </Button>
                  <Button variant="outline-success greenInput" type="submit" className="font-weight-bold">
                  <FontAwesomeIcon className="mr-2" icon={["fal", "cloud-arrow-up"]}/>
                    {t("manageVenuesScreen.saveAndPublish")}
                  </Button>
                </div>
                
              </Col>
            
            </Row>

            <Container>
              <Row className="justify-content-md-center mt-5 mb-4">
                {t(`cardModifyScreen.formDescription`)}
              </Row>
            </Container>
            <Container className="formContainer">
              <Row>
                <Col md={12}>
                  <h5 className="p-0 m-0 text-primary font-weight-bold">
                    {selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD ? t('cardModifyScreen.public') : t('cardModifyScreen.private')}
                  </h5>
                  <p>
                  {selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD ? t('cardModifyScreen.publicStatusDescription') : t('cardModifyScreen.privateStatusDescription')}
                  </p>
                </Col>
              </Row>
              <Row>
  <Col md={6}>
    <FormikTitleField
      name="text.active.en.title"
      languageCode="en"
      errors={errors}
      touched={touched}
      values={values}
      setFieldValue={setFieldValue}
      labelTextKey="cardModifyScreen.cardName"
      placeholderTextKey="cardModifyScreen.cardNamePlaceholderEN"
      syncWithField="text.passive.en.title"
    />
  </Col>
</Row>

{Boolean(titleAdditionalCountryField) && titleAdditionalCountryField?.countryCode && (
  <Row>
    <Col md={6}>
      <FormikTitleField
        name={`text.active.${titleAdditionalCountryField.countryCode}.title`}
        languageCode={titleAdditionalCountryField.countryCode}
        errors={errors}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
        placeholderTextKey={`cardModifyScreen.cardNamePlaceholder${titleAdditionalCountryField.countryCode.toUpperCase()}`}
        syncWithField={`text.passive.${titleAdditionalCountryField.countryCode}.title`}
        isAdditionalField
      />
    </Col>
  </Row>
)}

<Row>
  <Col md={6}>
    <FormikDescriptionField
      name="text.active.en.shortDescription"
      languageCode="en"
      errors={errors}
      touched={touched}
      values={values}
      setFieldValue={setFieldValue}
      labelTextKey="productModifyScreen.descriptionInEN"
      placeholderTextKey="cardModifyScreen.descriptionPlaceholderEN"
      syncWithField="text.passive.en.description"
    />
  </Col>
</Row>

{Boolean(descriptionAdditionalCountryField) && descriptionAdditionalCountryField?.countryCode && (
  <Row>
    <Col md={6}>
      <FormikDescriptionField
        name={`text.active.${descriptionAdditionalCountryField.countryCode}.shortDescription`}
        languageCode={descriptionAdditionalCountryField.countryCode}
        errors={errors}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
        labelTextKey={`productModifyScreen.descriptionIn${descriptionAdditionalCountryField.countryCode.toUpperCase()}`}
        placeholderTextKey={`cardModifyScreen.descriptionPlaceholder${descriptionAdditionalCountryField.countryCode.toUpperCase()}`}
        syncWithField={`text.passive.${descriptionAdditionalCountryField.countryCode}.description`}
        isAdditionalField
      />
    </Col>
  </Row>
)}

              <ImageUploadModal 
                show={showUploadStatusPhotoModal}
                imageURL={values.images.mainImage}
                aspectRatio={1500 / 900}
                cropShape="rect"
                onCroppedImage={(croppedImage) => {
                  setCroppedProductPhoto(croppedImage)
                }}
                onCancel={() => {
                  setShowUploadStatusPhotoModal(false)
                }}
                onSave={() => {
                  setShowUploadStatusPhotoModal(false)
                  handleUploadImage(croppedProductPhoto)
                }}/>

              {/* TODO: Use this once we need it */}
              <Row>
                <Col md={6}>
                  <Field 
                    name="images.mainImage"
                    render={() => (
                      <FormGroup controlId="images.mainImage" >
                        <FormLabel className={`textInputLabel mt-3 mb-0 ${errors.images?.mainImage && touched.images?.mainImage ? 'text-danger' : ''}`}>{t(`cardModifyScreen.cardPhoto`)}</FormLabel>
                        <div className="mb-2">
                          <small>{t('cardModifyScreen.cardPhotoDescription')}</small>
                        </div>

                        <label className="btn btn-secondary">
                            {t(`manageVenuesScreen.uploadPhoto`)}
                            <input 
                              type="file" 
                              id="images.mainImage"
                              hidden
                              name="file"
                              onChange={(event) => {
                                  if (event.currentTarget && event.currentTarget.files) {
                                    setFieldValue("images.mainImage", URL.createObjectURL(event.currentTarget.files[0]));
                                    setShowUploadStatusPhotoModal(true)
                                  }
                              }} />
                        </label>
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row style={{height: 200}}>
                <Col md={4}>
                  <MainImage/>
                </Col>
              </Row>
              {selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD &&
                <Row className="align-items-center">
                  <Col md={4}>
                    <Container className="p-0">
                      <Row>
                        <Col md={12}>
                          <h6 className={`mt-3 mb-0`}>{t(`cardModifyScreen.price`)}</h6>
                            <div className="mb-2">
                              <small>{t('cardModifyScreen.priceDescription')}</small>
                            </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                        <Field 
                          name="payments.price.amount"
                          render={() => (
                            <FormGroup controlId="payments.price.amount" >
                              <InputGroup>
                                <FormControl
                                  className="textInput text-center primaryInput"
                                  type="number"
                                  lang="en"
                                  value={values.payments?.price?.amount}
                                  onBlur={() => {
                                    // @ts-ignore
                                    if (values.payments?.price?.amount === "") {
                                      setFieldValue('payments.price.amount', initValues.payments.price?.amount)
                                    }
                                  }}
                                  onChange={(e) => {
                                    const newValue = parseFloat(e.target.value) <= 0 ? 1 : e.target.value
                                    setFieldValue('payments.price.amount', newValue)
                                  }} />
                                
                                <InputGroup.Append>
                                  <InputGroup.Text className="primaryInputGroupText">€</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                              
                            </FormGroup>
                          )}
                        />
                        </Col>
                      </Row>
                    </Container>
                  </Col>

                  <Col md={3}>
                    <Container className="p-0">
                      <Row>
                        <Col md={12}>
                          <FormLabel className={`textInputLabel invisible mt-3 mb-0 ${errors.payments?.vatPercentage && touched.payments?.vatPercentage ? 'text-danger' : ''}`}>{t(`cardModifyScreen.vat`)}</FormLabel>
                            <div className="mb-2">
                              <small>{t('cardModifyScreen.vat')}</small>
                            </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={8}>
                          <Field 
                            name="payments.vatPercentage"
                            render={() => (
                              <FormGroup controlId="payments.vatPercentage" >
                                <InputGroup>
                                  <FormControl 
                                    className="textInput text-center primaryInput" 
                                    type={'number'} 
                                    lang="en"
                                    isInvalid={errors.payments?.vatPercentage && touched.payments?.vatPercentage ? true : false}
                                    onBlur={() => {
                                      // @ts-ignore
                                      if (values.payments?.vatPercentage === "") {
                                        setFieldValue('payments.vatPercentage', initValues.payments.vatPercentage)
                                      }
                                    }}
                                    value={values.payments?.vatPercentage} 
                                    onChange={(e) => {
                                      const newValue = parseFloat(e.target.value) <= 0 ? 0 : e.target.value
                                      setFieldValue('payments.vatPercentage', newValue)
                                    }} />
                                  {errors.payments?.vatPercentage && touched.payments?.vatPercentage ? (
                                    <small className="text-danger">{errors.payments?.vatPercentage}</small>
                                  ) : null}

                                  <InputGroup.Append>
                                    <InputGroup.Text className="primaryInputGroupText">%</InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              }

              <Row className="align-items-center">
                <Col md={4}>
                  <Container className="p-0">
                    <Row>
                      <Col md={12}>
                        <h6 className={`mt-3 mb-0 ${errors.flags?.grantEntrance && touched.flags?.grantEntrance ? 'text-danger' : ''}`}>{t(`cardModifyScreen.entrance`)}</h6>
                          <div className="mb-2">
                            <small>{t('cardModifyScreen.entranceDescription')}</small>
                          </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Field 
                          name="flags.grantEntrance"
                          render={() => (
                            <FormGroup controlId="flags.grantEntrance">
                              <FormControl 
                                className="textInput text-center primaryInput" 
                                as='select' 
                                isInvalid={errors.flags?.grantEntrance && touched.flags?.grantEntrance ? true : false} 
                                // @ts-ignore
                                value={values.flags?.grantEntrance} 
                                onChange={handleChange}>
                                  <option value="true">{t('cardModifyScreen.yes')}</option>
                                  <option value="false">{t('cardModifyScreen.no')}</option>
                              </FormControl>
                              {errors.flags?.grantEntrance && touched.flags?.grantEntrance ? (
                                <small className="text-danger">{errors.flags?.grantEntrance}</small>
                              ) : null}
                            </FormGroup>
                          )}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col md={4}>
                  <Container className="p-0">
                    <Row>
                      <Col md={12}>
                        <h6 className={`mt-3 mb-0 ${errors.flags?.grantCloakroom && touched.flags?.grantCloakroom ? 'text-danger' : ''}`}>{t(`cardModifyScreen.cloakroom`)}</h6>
                          <div className="mb-2">
                            <small>{t('cardModifyScreen.cloakroomDescription')}</small>
                          </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Field 
                          name="flags.grantCloakroom"
                          render={() => (
                            <FormGroup controlId="flags.grantCloakroom">
                              <FormControl 
                                className="textInput text-center primaryInput" 
                                as='select' 
                                isInvalid={errors.flags?.grantCloakroom && touched.flags?.grantCloakroom ? true : false} 
                                // @ts-ignore
                                value={values.flags?.grantCloakroom} 
                                onChange={handleChange}>
                                <option value="true">{t('cardModifyScreen.yes')}</option>
                                <option value="false">{t('cardModifyScreen.no')}</option>
                              </FormControl>
                              {errors.flags?.grantCloakroom && touched.flags?.grantCloakroom ? (
                                <small className="text-danger">{errors.flags?.grantCloakroom}</small>
                              ) : null}
                            </FormGroup>
                          )}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col md={4}>
                  <Container className="p-0">
                    <Row>
                      <Col md={12}>
                        <h6 className={`mt-3 mb-0 ${errors.flags?.showExternalIdentifier && touched.flags?.showExternalIdentifier ? 'text-danger' : ''}`}>{t(`cardModifyScreen.cardNumberVisibilityTitle`)}</h6>
                          <div className="mb-2">
                            <small>{t('cardModifyScreen.cardNumberVisibilityDescription')}</small>
                          </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Field 
                          name="flags.showExternalIdentifier"
                          render={() => (
                            <FormGroup controlId="flags.showExternalIdentifier">
                              <FormControl 
                                className="textInput text-center primaryInput" 
                                as='select' 
                                isInvalid={errors.flags?.showExternalIdentifier && touched.flags?.showExternalIdentifier ? true : false} 
                                // @ts-ignore
                                value={values.flags?.showExternalIdentifier} 
                                // value={false}
                                onChange={handleChange}>
                                  <option value="true">{t('cardModifyScreen.yes')}</option>
                                  <option value="false">{t('cardModifyScreen.no')}</option>
                              </FormControl>
                              {errors.flags?.showExternalIdentifier && touched.flags?.showExternalIdentifier ? (
                                <small className="text-danger">{errors.flags?.showExternalIdentifier}</small>
                              ) : null}
                            </FormGroup>
                          )}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>

              </Row>
              {selectedStatusPaymentType === VENUE_STATUS_TYPE.SOLD &&
                <>
                  <Row>
                    <Col md={6}>
                      <h6 className={`mb-0 ${errors.payments?.type && touched.payments?.type ? 'text-danger' : ''}`}>{t(`cardModifyScreen.validityType`)}</h6>
                        <div className="mb-2">
                          <small>{t('cardModifyScreen.validityTypeDescription')}</small>
                        </div> 
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <Field 
                        name="payments.type"
                        render={() => (
                          <FormGroup controlId="payments.type">
                            <FormControl className="textInput text-center primaryInput" as='select' isInvalid={errors.payments?.type && touched.payments?.type ? true : false} value={values.payments?.type} onChange={(e) => {
                              setFieldValue('payments.type', e.target.value)

                              if (e.target.value === MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FLEXIBLE) {
                                setFieldValue('payments.duration', "365")
                                setFieldValue('payments.validFrom', undefined)
                                setFieldValue('payments.validThrough', undefined)
                              } else {
                                setFieldValue('payments.duration', undefined)
                              }
                            }}>
                              <option value={MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FIXED}>{t('cardModifyScreen.dateRange')}</option>
                              <option value={MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FLEXIBLE}>{t('cardModifyScreen.time')}</option>
                            </FormControl>
                            {errors.payments?.type && touched.payments?.type ? (
                              <small className="text-danger">{errors.payments?.type}</small>
                            ) : null}
                          </FormGroup>
                        )}
                      />
                    </Col>
                  </Row>

                  {values.payments.type === MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FLEXIBLE &&
                    <>
                      <Row>
                        <Col md={6}>
                            <div className="mb-2">
                              <small>{t('cardModifyScreen.timeDescription')}</small>
                            </div> 
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <Field 
                            name="payments.duration"
                            render={() => (
                              <FormGroup controlId="payments.duration">
                                <FormControl className="textInput text-center primaryInput" as='select' isInvalid={errors.payments?.duration && touched.payments?.duration ? true : false} value={values.payments?.duration} onChange={handleChange}>
                                  <option value="30">1</option>
                                  <option value="90">3</option>
                                  <option value="180">6</option>
                                  <option value="365">12</option>
                                  <option value="730">24</option>
                                </FormControl>
                                {errors.payments?.duration && touched.payments?.duration ? (
                                  <small className="text-danger">{errors.payments?.duration}</small>
                                ) : null}
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>
                    </>
                  }
                  {values.payments.type === MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FIXED &&
                    <>
                      <Row className="align-items-center mb-2">
                        <Col md={4}>
                          <Container className="p-0">
                            <Row>
                              <Col md={12}>
                                  <div className="mb-2">
                                    <small>{t('cardModifyScreen.validFrom')}</small>
                                  </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <DatePicker selected={values.payments.validFrom ? new Date(values.payments.validFrom) : undefined}
                                  onChange={(date) => {
                                    setFieldValue('payments.validFrom', moment(date).utc().format().toString())
                                    setFieldValue('payments.validThrough', undefined)
                                  }}
                                  minDate={new Date()}
                                  className="datePicker pl-3 pt-1 pb-1 primaryInput"
                                  dateFormat="P"
                                  locale={i18n.languages[0]}/>
                                  {errors.payments?.validFrom && touched.payments?.validFrom ? (
                                    <small className="text-danger position-absolute">{errors.payments?.validFrom}</small>
                                  ) : null}
                              </Col>
                            </Row>
                          </Container>
                        </Col>

                        <Col md={4}>
                          <Container className="p-0">
                            <Row>
                              <Col md={12}>
                                  <div className="mb-2">
                                    <small>{t('cardModifyScreen.validUntil')}</small>
                                  </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} className="datePickerParent">
                                <DatePicker 
                                  selected={values.payments.validThrough ? new Date(values.payments.validThrough) : undefined}
                                  onChange={(date) => setFieldValue('payments.validThrough', moment(date).utc().format().toString())}
                                  // @ts-ignore
                                  minDate={new Date(values.payments.validFrom)}
                                  className="datePicker pl-3 pt-1 pb-1 primaryInput"
                                  dateFormat="P"
                                  locale={i18n.languages[0]}/>
                                  {errors.payments?.validThrough && touched.payments?.validThrough ? (
                                    <small className="text-danger position-absolute">{errors.payments?.validThrough}</small>
                                  ) : null}
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                    </>
                  }
                </>
              }
              
              <Row className="align-items-center">
                {/* <Col md={4}>
                  <Container className="p-0">
                    <Row>
                      <Col md={12}>
                        <h6 className={`mt-3 mb-0 ${errors.style?.theme && touched.style?.theme ? 'text-danger' : ''}`}>{t(`cardModifyScreen.cardColor`)}</h6>
                          <div className="mb-2">
                            <small>{t('cardModifyScreen.cardColorDescription')}</small>
                          </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Field 
                          name="style.theme"
                          render={() => (
                            <FormGroup controlId="style.theme">
                              <FormControl className="textInput text-center primaryInput" as='select' isInvalid={errors.style?.theme && touched.style?.theme ? true : false} value={values.style?.theme} onChange={handleChange}>
                                <option value={VENUE_STATUS_THEME.BLACK}>{t('cardModifyScreen.black')}</option>
                                <option value={VENUE_STATUS_THEME.GOLD}>{t('cardModifyScreen.gold')}</option>
                              </FormControl>
                              {errors.flags?.grantCloakroom && touched.flags?.grantCloakroom ? (
                                <small className="text-danger">{errors.flags?.grantCloakroom}</small>
                              ) : null}
                            </FormGroup>
                          )}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col> */}

                <Col md={5}>
                  <FormGroup controlId="visible">
                  <BootstrapForm.Check 
                      type="switch"
                      id="visible"
                      className="mt-3"
                      checked={!values.visible}
                      onChange={() => {
                        if (!values.visible) {
                          setFieldValue('visible', true)
                        } else {
                          setShowHideProductModal(true)
                        }
                      }}
                      label={t("cardModifyScreen.hideCard")}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Modal show={showHideProductModal} centered onHide={() => {
                  setShowHideProductModal(false)
              }}>
                <Modal.Header closeButton>
                  <Modal.Title>{t("cardModifyScreen.hideCardTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("cardModifyScreen.hideCardDescription")}</Modal.Body>
                <Modal.Footer>
                  <Button variant="outline-primary" onClick={() => {
                    setShowHideProductModal(false)
                    setFieldValue('visible', true)
                  }}>
                    {t("manageVenuesScreen.cancel")}
                  </Button>
                  <Button variant="primary" onClick={() => {
                    setShowHideProductModal(false)
                    setFieldValue('visible', false)
                  }}>
                    {t("manageVenuesScreen.hide")}
                  </Button>
                </Modal.Footer>
              </Modal>
              
              <Row>
                <Col md={6}>
                  <Button variant="outline-success greenInput mt-4" type="submit" className="font-weight-bold">
                  <FontAwesomeIcon className="mr-2" icon={["fal", "cloud-arrow-up"]}/>
                    {t("manageVenuesScreen.saveAndPublish")}
                  </Button>
                </Col>
              </Row>

            </Container>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default withTranslation()(inject("userSessionStore", "api", "memberStore")(observer(CreateUpdateMemberStatusScreen)))
