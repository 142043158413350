import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject, observer } from "mobx-react"
import React from "react"
import { useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Modal from "react-bootstrap/esm/Modal"
import Row from "react-bootstrap/Row"
import { withTranslation, WithTranslation } from "react-i18next"
import { RouteComponentProps, match } from "react-router"
import { MerchantAdminApi } from "../Api"
import AlertMessage from "../components/AlertMessage"
import { MemebershipsSummaryCard } from "../components/MemberStatusSummaryCard"
import { MembersLocation, MemberStatusModifyLocation } from "../Locations"
import { IMemberStore, VENUE_STATUS_TYPE } from "../models/MemberStore"
import { IRootStore } from "../models/RootStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import {Form as BootstrapForm} from "react-bootstrap"

const MANAGE_MEMEBER_CARDS_SCREEN_ERROR = "manageMemberCardsScreen"

interface matchParam {
  id?: string
}


export interface ManageMemberStatusesScreenProps extends RouteComponentProps, WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  memberStore?: IMemberStore
  rootStore?: IRootStore
  match: match<matchParam>
}

export const STATUS_TYPE = {
  PUBLIC: 'PURCHASE',
  PRIVATE: 'PRIVATE'
}


const PAYMENT_TYPE = {
  RECURRING: 'RECURRING'
}

const ManageMembersCardsScreen = (props: ManageMemberStatusesScreenProps) => {
  const {userSessionStore, history, t} = props
  const {match: {params: {id}}} = props
  // TODO: Fix to return the data correctly from the URL statusType should return statusType, shouldnt come as id
  const statusType = id
  const [showAddCardModal, setShowAddCardModal] = useState(false);

  const getVenueIDFromURL = () => {
    const urlVars = window.location.pathname.split('/')
    if (urlVars.length >= 7 && urlVars[7] === 'venue') {
      return urlVars[8]
    } else {
      return ''
    }
  }

  const venueId = getVenueIDFromURL()
  const selectedVenue = userSessionStore?.currentOrganization?.venues.find(venue => venue.id === venueId)

  // TODO: Take a look if optimization is needed: Store member statuses to Venue model? So filtering my venueId is not needed?
  const activeMemberStatuses = 
    props.memberStore?.statuses
    .filter(status => status.payments.type !== PAYMENT_TYPE.RECURRING)
    .filter(status => status.visible && status.restaurantId.id === venueId)
    .filter(status => status.type === statusType) || []
  const inactiveMemberStatuses = 
    props.memberStore?.statuses
    .filter(status => status.payments.type !== PAYMENT_TYPE.RECURRING)
    .filter(status => !status.visible && status.restaurantId.id === venueId)
    .filter(status => status.type === statusType) || []

  
  const navToCardModifyScreen = (statusType: string, statusId: string) => history.push(MemberStatusModifyLocation.toUrl({...selectedVenue?.urlFields(), statusId: statusId, statusType: statusType}))

  const pageDescriptionText = statusType === STATUS_TYPE.PUBLIC ? t("manageMembersCardScreen.publicCardsDescription") : t("manageMembersCardScreen.privateCardsDescription")

  const onAddNewCard = () => statusType === STATUS_TYPE.PUBLIC ? navToCardModifyScreen(VENUE_STATUS_TYPE.SOLD, "NEW__") : navToCardModifyScreen(VENUE_STATUS_TYPE.GIVEN, "NEW__")


  const getVenueReferralCode = (statusId: string) => {
    const referralCode = props.memberStore?.referralCodes.find(referralCode => referralCode.appliesTo.target_id === statusId)
    return referralCode
  }

  const refetchVenueCards = async () => {
  try {
    await props.memberStore?.fetchInvitationCodes()
  } catch (error) {
    console.error('Error refetching venue cards:', error);
  }
};

  return (
    <div>

      <AlertMessage source={MANAGE_MEMEBER_CARDS_SCREEN_ERROR}/>
      <Container>
        <Row>
          <Col className="col-4 p-0">
            <Button
              onClick={() => history.push(MembersLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))}
              variant="secondary">
                <FontAwesomeIcon className="mr-2" icon={["fal", "chevrons-left"]}/>
                {t("manageMembersCardScreen.back")}
            </Button>
          </Col>
          <Col className="col-8 p-0 text-right">
          </Col>
        </Row>
      </Container>
      <div className="text-center mt-5 mb-5">
        <h2 className="p-0 m-0 text-primary font-weight-bold mb-2">{statusType === STATUS_TYPE.PUBLIC ? t("manageMembersCardScreen.publicCards") : t("manageMembersCardScreen.privateCards")} - {selectedVenue?.name}</h2>
        {pageDescriptionText.split('\n').map(text => 
          <p className="p-0 m-0 font-size-medium">{text}</p>
        )}
        
      </div>

      <Container>
        <Row>
          <Col className="col-6">
            <h4 className="mt-3 text-primary">{t("manageMembersCardScreen.activeCards")} ({activeMemberStatuses.length})</h4>
          </Col>
          <Col className="col-6 d-flex align-items-center justify-content-end">
            <Button
              onClick={onAddNewCard}>
                <FontAwesomeIcon className="mr-2" icon={["fal", "shopping-cart"]}/>
                {t("manageMembersCardScreen.addNewCard")}
            </Button>


          </Col>
        </Row>
      </Container>

      <div className="mt-3">
        {activeMemberStatuses?.length > 0 
          ? activeMemberStatuses.map((memberStatus) => <MemebershipsSummaryCard key={memberStatus.id} 
          onGenerateCode={(customCode) => props.memberStore?.generateVenueStatusInvitationCode({
            statusId: memberStatus.id,
            venueId: venueId,
            codeId: getVenueReferralCode(memberStatus.id)?.id,
            customCode: customCode,
          })} 
          onRefetchVenueCards={refetchVenueCards}
          referralCode={getVenueReferralCode(memberStatus.id)} 
          onEdit={() => navToCardModifyScreen(memberStatus.type, memberStatus.id)} memberStatus={memberStatus}/>)
          : <p className="font-size-medium">{t("manageMembersCardScreen.noActiveCards")}</p>}
      </div>

      <h4
        className="mt-5 text-primary">{t("manageMembersCardScreen.inactiveCards")} ({inactiveMemberStatuses?.length})</h4>
      <div className="mt-3">
      {inactiveMemberStatuses?.length > 0 
          ? inactiveMemberStatuses?.map((memberStatus) => <MemebershipsSummaryCard key={memberStatus.id} onEdit={() => navToCardModifyScreen(memberStatus.type, memberStatus.id)} memberStatus={memberStatus}/>)
          : <p className="font-size-medium">{t("manageMembersCardScreen.noInactiveCards")}</p>}
      </div>
    </div>
  )
}

export default withTranslation()(inject("userSessionStore", "memberStore", "rootStore", "api")(observer(ManageMembersCardsScreen)))
