import _ from "lodash";
import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useStores } from "../../../hooks/UseStores";
import { MemberLocation, MembersLocation } from "../../../Locations";
import { IMember } from "../../../models/Member";
import { colors } from "../../../theme/colors";
import {getOrganizationTimezone, timeAgo} from "../../../utils/dateUtils";
import blankProfileImage from "./blank-profile.png";

const cardStyle: CSSProperties = {
  backgroundColor: "#1e1e1e",
  color: "#ffffff",
  borderRadius: "10px",
};

const listItemStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
};

const imageStyle: CSSProperties = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  objectFit: "cover",
  marginRight: "10px",
};

const infoStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
};


const joinedStyle: CSSProperties = {
  fontSize: "12px",
  color: "#aaaaaa",
};



const NewMembersList: React.FC<{newMembers: IMember[], loading: boolean}> = observer(({newMembers, loading}) => {
  const { t } = useTranslation();
  const { userSessionStore } = useStores();
  const history = useHistory();

  const formatTimezone: string = getOrganizationTimezone(userSessionStore)


  if (loading) {
    return  <div style={{ textAlign: 'center', marginTop: '20px'}}>
      
      <Spinner animation="border" role="status"></Spinner>
      </div>
  }

    const handleMemberClick = (memberId: string) => {
    history.push(MemberLocation.toUrl({
      ...userSessionStore!.selectedBranch!.urlFields(),
      memberId: memberId
    }));
  };


  return (
    <Card className="mt-2" style={cardStyle}>
      <Card.Header as="h5" style={{
       backgroundColor: "#252525",
       borderLeft: `1px solid ${colors.stroke}`,
       borderTop: `1px solid ${colors.stroke}`,
       borderRight: `1px solid ${colors.stroke}`,
     
      }}>{t("homeScreen.newMembers")}</Card.Header>
      <Card.Body>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {newMembers.map((member) => {
            const oldestMembership = _.minBy(member.venueMemberships, "memberSince");
            return (
              <li key={member.id} style={{...listItemStyle, cursor: "pointer"}} onClick={() => handleMemberClick(member.id)}>
                <img
                  src={member.profileImages?.small || blankProfileImage}
                  alt={member.name}
                  style={imageStyle}
                />
              <div style={infoStyle}>
              <div>
  {member.name}{oldestMembership ? ` · ${timeAgo(oldestMembership.memberSince, formatTimezone)}` : ""}
</div>
               
              </div>
              </li>
            );
          })}
        </ul>
        {newMembers && newMembers.length > 10 &&
          <div style={{ textAlign: "center", marginTop: "10px" }}>
          <Button
                onClick={() =>{
                  window.scrollTo(0, 0);
                   history.push(MembersLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
                }}
                variant="secondary">
                {t("homeScreen.seeMoreMembers")}
              </Button>
          </div>
        }
     
      </Card.Body>
    </Card>
  );
});

export default NewMembersList;