import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, Form, Formik } from 'formik'
import log from "loglevelnext";
import { inject, observer } from "mobx-react"
import moment from "moment-timezone"
import { useEffect, useRef, useState } from "react"
import { Container } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Card from 'react-bootstrap/Card'
import Col from "react-bootstrap/Col"
import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import Modal from 'react-bootstrap/Modal'
import Row from "react-bootstrap/Row"
import DatePicker from "react-datepicker"
import { WithTranslation, withTranslation } from "react-i18next"
import Skeleton from 'react-loading-skeleton'
import Select from 'react-select'
import { v4 as uuidv4 } from "uuid"
import * as Yup from 'yup'
import { MerchantAdminApi } from "../../Api"
import { MessagesLocation } from "../../Locations"
import AlertMessage from "../../components/AlertMessage"
import ImageUploadModal from "../../components/ImageUploadModal"
import i18n from "../../i18n"
import { IMemberStore } from "../../models/MemberStore"
import { IMessageStore } from "../../models/MessageStore"
import { IUserSessionStore } from "../../models/UserSessionStore"
import { urlToFile } from "../../utils"
import "./CreateUpdateVenueScreen.scss"
import { colors } from "../../theme/colors"
import {getOrganizationTimezone} from "../../utils/dateUtils";
import CancelScheduleMessageModal from "./CancelScheduleMessageModal"

const ADD_VENUE_ERROR = "addVenueError"

export interface SnedPushNotificationScreenProps extends WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  messageStore?: IMessageStore
  memberStore?: IMemberStore
  history?: any
}

const SEND_STATUS = {
    DRAFT: 'DRAFT',
    READY: 'READY',
    SENT: 'SENT',
    PROCESSING: 'PROCESSING'
  }

const SendPushNotificationScreen = (props: SnedPushNotificationScreenProps) => {
  const {t, userSessionStore, api, history, messageStore, memberStore} = props
  
  const [showGoBackModal, setShowGoBackModal] = useState(false);
  const [showCancelScheduleModal, setShowCancelScheduleModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState('')
  const [selectedSendStatus, setSelectedSendStatus] = useState('');
  const [showUploadLImagePhotoModal, setShowUploadImagePhotoModal] = useState(false);

  const selectedMessage = messageStore?.messages.find(message => message.id === history.location?.state?.messageId)

  const [isCustomTime, setIsCustomTime] = useState(selectedMessage?.isCustomTime || false);

  const [uploadedMessageImage, setUploadedMessageImage] = useState(selectedMessage?.payload.images?.mainImage || undefined);
  const venuesNames = userSessionStore?.currentOrganization?.venues.map(venue => venue.name) || []

  const formatTimezone = getOrganizationTimezone(userSessionStore)

  const validationSchema = Yup.object().shape({
    message: Yup.string().required('Message is required'),
    imageURL: Yup.string().nullable(true),
    selectedVenues: Yup.array().of(Yup.string()),
    selectedStatuses: Yup.array().of(Yup.string())
  });
  const isSingleVenue = userSessionStore!.currentOrganization?.isSingleVenue()
  const isOrganizationContext = userSessionStore?.isOrganizationContext()

  const isSpecificVenueSelectedInMultiVenueOrganization = Boolean(!isSingleVenue && !isOrganizationContext)

  const selectedVenueId = Boolean(userSessionStore!.selectedBranch?.id) ? userSessionStore!.selectedBranch?.id : undefined

  const nowTime = moment().utc()
  const isSendTimeInThePast = moment(selectedMessage?.sendTime).utc().isBefore(nowTime)

  const isMessageSent = selectedMessage?.status === SEND_STATUS.SENT && isSendTimeInThePast
  const isEditingDisabled = isMessageSent || selectedMessage?.status === SEND_STATUS.PROCESSING

  const newMessageValues = {
    id: `NEW__${uuidv4()}`,
    toMembersInVenue: venuesNames.length > 0 ? venuesNames[0] : '',
    
    startSendingTime: moment(new Date()).tz(formatTimezone).format('YYYY-MM-DD HH:mm:ss'),
    message: '',
    imageURL: undefined,
    selectedVenues: isSpecificVenueSelectedInMultiVenueOrganization ? [
      ...(selectedVenueId ? [selectedVenueId] : [])
    ] : [],
    selectedStatuses: [] 
  }

  const initValues = selectedMessage ? {
    id: selectedMessage.id,
    toMembersInVenue: selectedMessage.payload.toMembersInVenue || newMessageValues.toMembersInVenue,
    imageURL: selectedMessage?.payload.images?.mainImage || undefined,
    startSendingTime: selectedMessage.sendTime ? moment(selectedMessage.sendTime).tz(formatTimezone).format('YYYY-MM-DD HH:mm:ss') : newMessageValues.startSendingTime,
    message: selectedMessage.payload.message || newMessageValues.message,
    selectedVenues: selectedMessage.configuration?.venues?.map(venue => venue?.id) || [],
    selectedStatuses: selectedMessage.configuration?.venues?.flatMap(venue => venue?.statuses?.map(status => status?.id)) || []
  } : {
    ...newMessageValues
  }

  const [recipientCount, setRecipientCount] = useState<number | undefined>(undefined);

  const onBackToVenues = (isFormEdited: boolean) => {
    if (isFormEdited)
      setShowGoBackModal(true)
    else
      history.push(MessagesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
  }

  const filterSelectedVenuesNotInSelectedStatuses = (selectedVenues: string[], selectedStatuses: string[]) => {

    if (selectedStatuses.length === 0) {
      return selectedVenues
    }

    return selectedVenues.filter(venueId => 
      selectedStatuses.some(statusId => {
        const status = memberStore?.statuses.find(status => status.id === statusId);
        return status?.restaurantId?.id === venueId;
      })
    );
  };

  const fetchRecipientCount = async ({selectedVenues, selectedStatuses}: {
    selectedVenues: string[],
    selectedStatuses: string[]
  }) => {
    let filteredSelectedVenues = filterSelectedVenuesNotInSelectedStatuses(selectedVenues, selectedStatuses);
    const isNoSelectedVenues =  !Boolean(filteredSelectedVenues?.length)
    const isNoSelectedStatuses = !Boolean(selectedStatuses?.length)
    if (isNoSelectedVenues && isNoSelectedStatuses) {
      filteredSelectedVenues = userSessionStore!.currentOrganization!.venues.map(venue => venue.id);
    }
    const count = await memberStore!.getMessageRecipientCount({selectedVenues: filteredSelectedVenues, selectedStatuses});

    setRecipientCount(count || 0);
  };

  useEffect(() => {
    if (recipientCount === undefined) {
      const filteredSelectedVenues = initValues.selectedVenues.filter((venue): venue is string => venue !== undefined) as string[];
      const filteredSelectedStatuses = initValues.selectedStatuses.filter((status): status is string => status !== undefined) as string[];

      fetchRecipientCount({
        selectedVenues: filteredSelectedVenues,
        selectedStatuses: filteredSelectedStatuses
      });
    }
  }, [recipientCount]);


  const handleSaveAndPublish = async (messages: any[], newMessage: boolean) => {
    const response = await api!.postMessages(messages, undefined, ADD_VENUE_ERROR)
    if (response && response.messages.length > 0) {
      const responseMessage = response.messages[0]
        if (newMessage) {
            messageStore?.addMessage(responseMessage)
        } else {
            messageStore?.updateMessage(responseMessage)
        }
        history.push(MessagesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    }
  }

  const IMAGE_UPLOAD_STATUS = {
    UPLOADING: "UPLOADING",
    ERROR: "ERROR"
  }

  const MessageImage = () => {
    if (uploadedMessageImage === undefined) {
      return <Card className="croppedCover" style={{margin: '0px auto'}}/>
    } else if (uploadedMessageImage === IMAGE_UPLOAD_STATUS.UPLOADING) {
      return <Skeleton height={198} width={330}/>
    } else if (uploadedMessageImage === IMAGE_UPLOAD_STATUS.ERROR) {
      return <Card className="croppedCover" style={{margin: '0px auto'}}>
        <div className="alert alert-danger mt-5" role="alert">
          {t('manageVenuesScreen.imageUploadFailed')}
        </div>
      </Card>
    } else {
      return <img className="croppedCover" src={uploadedMessageImage} alt="logo"/>
    }
  }

  const handleUploadImage = async (imageBlob: any) => {
    setUploadedMessageImage(IMAGE_UPLOAD_STATUS.UPLOADING)
    const imageFile = await urlToFile(imageBlob, 'image.jpeg', 'image/jpeg')
    const imageURL = await api!.uploadImage('MESSAGE_PUSH_BODY', imageFile)
    if (imageURL) {
      setUploadedMessageImage(imageURL)
    } else {
      setUploadedMessageImage(IMAGE_UPLOAD_STATUS.ERROR)
    }
  }

  interface OptionType {
    value: string;
    label: string;
  }

  const AudienceCount = () => {
    return <>
              <div style={{
            textAlign: 'center',
       
          }}>
            <FormLabel style={{
                   fontSize: 18,
                   fontWeight: 'bold',
                   margin: 0,
            }} className={`textInputLabel`}>{t("sendPushNotificationsScreen.audience")}</FormLabel>
          </div>

          <div style={{
                  display: 'flex',
                  height: isSingleVenue ? 26 : 40,
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  fontSize: 14,
                  color: colors.secondaryText,
                  paddingTop: 5,
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}>
                    {recipientCount !== undefined ? t("sendPushNotificationsScreen.messageRecipientsCount", {recipientsCount: recipientCount || 0}) : ''}
                </div>
    </>
  }


  const VenuesSelectionDropdown = ({ values, setFieldValue }: { values: any, setFieldValue: (field: string, value: any) => void }) => {
   const options = userSessionStore!.currentOrganization!.venues!.map(venue => ({
      value: venue.id,
      label: venue.name
    })) || [];

    const selectedOptions = options.filter(option => values.selectedVenues.includes(option.value)) || []


    const handleChange = (selectedOptions: OptionType[]) => {
      const newSelectedVenues = selectedOptions?.map(option => option.value) || [];
      let newSelectedStatuses = values.selectedStatuses
      
      if (newSelectedVenues.length > 0) {
        newSelectedStatuses = newSelectedStatuses.filter((statusId: string) => {
          const status = memberStore?.statuses.find(status => status.id === statusId);
          return status?.restaurantId?.id ? newSelectedVenues.includes(status?.restaurantId?.id) : false;
        });
      }
   
  
      setFieldValue('selectedVenues', newSelectedVenues || []);
      setFieldValue('selectedStatuses', newSelectedStatuses || []);  
      fetchRecipientCount({selectedVenues: newSelectedVenues, selectedStatuses: newSelectedStatuses});
    };

  

    if (isSingleVenue) {
      return null
    }
  

    return (
      <Row>
      <Col md={4}></Col>
      <Col md={4}>
        <div>
            <Select
        isMulti
        options={options}
        onChange={handleChange}
        isDisabled={isEditingDisabled}
        value={selectedOptions}
        className="react-select-container react-select-container-benefits-statuses react-select_message_placeholder"
        classNamePrefix="react-select"
        placeholder={t("rewardModifyScreen.allVenues")}

      />
        </div>


 
        </Col>
        <Col md={4}></Col>

      </Row>

    );
  };

  const getActiveMemberStatusesAndSubscriptions = ({values}: {values: any}) => {
    const activeMemberStatusesAndSubscriptions = memberStore?.statuses?.filter((status: any) => {
      const isNoSelectedVenues = values.selectedVenues.length === 0 
      const isBelongingToSelectedVenues = values.selectedVenues.includes(status.restaurantId.id)
      const venueStatusStats = memberStore!.stats?.venueStatuses?.find((venueStatus: any) => venueStatus.id?.id === status.id);

      const hasMembers = venueStatusStats && venueStatusStats.membersCount > 0;
    
  
      const shouldBeShown = (isNoSelectedVenues || isBelongingToSelectedVenues) && hasMembers
      return shouldBeShown && status.visible
    })
  
    return activeMemberStatusesAndSubscriptions
  }

  const StatusesSelectionDropdown = ({values, setFieldValue}: {values: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void}) => {

    const activeMemberStatusesAndSubscriptions = getActiveMemberStatusesAndSubscriptions({values})


    const options = activeMemberStatusesAndSubscriptions?.map((status: any) => ({
      value: status.id,
      label: isSingleVenue ? status.localeTitle : `${status.localeTitle} (${status.restaurantId.name})`
    })) || [];


  const handleChange = (selectedOptions: OptionType[]) => {
    const newSelectedStatuses = selectedOptions?.map(option => option.value) || []
    setFieldValue('selectedStatuses', newSelectedStatuses, false);
    fetchRecipientCount({selectedVenues: values.selectedVenues, selectedStatuses: newSelectedStatuses});
  };

  const venueHasStatuses = Boolean(activeMemberStatusesAndSubscriptions?.length )

  if (!venueHasStatuses) {
    return null
  }

  const selectedOptions = options.filter(option => values.selectedStatuses.includes(option.value))
    return (
      <Row className="mt-2 mb-4">
      <Col md={4}></Col>
      <Col md={4}>
      <div>
        <div style={{
          textAlign: 'center'
        }}>

            <FormLabel className={`textInputLabel`}>{t("sendPushNotificationsScreen.targetTo")}</FormLabel>
        </div>
      <Select
        isMulti
        options={options}
        isDisabled={isEditingDisabled}
        onChange={handleChange}
        value={selectedOptions}
        className="react-select-container react-select-container-benefits-statuses react-select_message_placeholder"
        classNamePrefix="react-select"
        placeholder={t("benefitModifyScreen.modifyCard.allMembers")}
        
      />
      </div>
      </Col>
              <Col md={4}></Col>
     
            </Row>
    );
  };


  // TODO: Figure out what is the this.state.venue
  // .filter(status => status.visible && status.restaurantId.id === this.state.venue?.id)





  const getConfigurationForVenuesAndStatusesFilters = ({values}: {values: any}) => {

    const filteredSelectedVenues = filterSelectedVenuesNotInSelectedStatuses(values.selectedVenues, values.selectedStatuses);

    const configuration = {
      venues: filteredSelectedVenues?.map((venueId: string) => ({
        id: venueId,
        statuses: values?.selectedStatuses?.map((statusId: string) => {
          const status = memberStore?.statuses?.find(s => s.id === statusId);
          if (status && status.restaurantId?.id === venueId) {
            return { id: statusId }; 
          }
          return null;
        }).filter((status: any) => status !== null) 
      }))
    };
  
    const setupVenuesConfigForSelectedStatuses = () => {
      values.selectedStatuses.forEach((statusId: string) => {
        const status = memberStore?.statuses.find(s => s.id === statusId);
        const isNoVenueInSelectedVenues = status && !filteredSelectedVenues.includes(status.restaurantId.id)

        if (status && isNoVenueInSelectedVenues) {
          const venueIndex = configuration.venues.findIndex((venue: any) => venue.id === status.restaurantId.id);
          const isVenueInConfiguration = venueIndex !== -1
          if (!isVenueInConfiguration) {
            configuration.venues.push({
              id: status.restaurantId.id,
              statuses: [{ id: statusId }]
            });
          } else {
            configuration.venues[venueIndex].statuses.push({ id: statusId });
          }
        }
      });
    };
  

    setupVenuesConfigForSelectedStatuses()

    return configuration
  }


  const CancelScheduledMessageButton = () => {
  


  const isShown = Boolean(isCustomTime  && selectedMessage?.status === SEND_STATUS.SENT)


  if (!isShown) {
    return null
  }

  if (isSendTimeInThePast) {
    return null
  }

  return (
    <Button variant="secondary mt-4 mr-2" style={{float: 'right'}}  onClick={() => setShowCancelScheduleModal(true)}>
      {t("sendPushNotificationsScreen.cancelScheduleMessageModal.cancelMessage")}
    </Button>
  );
};


  return (
    <Container>
      <AlertMessage source={ADD_VENUE_ERROR}/>

      <CancelScheduleMessageModal
        show={showCancelScheduleModal}
        onHide={() => setShowCancelScheduleModal(false)}
        onConfirm={() => {
          setShowCancelScheduleModal(false);
        }}
        messageId={selectedMessage?.id}
/>

      <Modal show={showGoBackModal} centered onHide={() => {
        setShowGoBackModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{t("messagesScreen.goBackPopup.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("messagesScreen.goBackPopup.description")}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => {
            setShowGoBackModal(false)
          }}>
            {t("messagesScreen.goBackPopup.cancel")}
          </Button>
          <Button variant="primary" onClick={() => {
            history.push(MessagesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
          }}>
            {t("messagesScreen.goBackPopup.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
            const selectedVenue = userSessionStore!.currentOrganization?.venues.find(venue => venue.name === values.toMembersInVenue)
            const nowTime = moment(new Date()).utc().add('seconds', 15).format('YYYY-MM-DD HH:mm:ss')
            const sentToMembersCount = memberStore?.members.filter(member => member.venueMemberships.find(venueMembership => venueMembership.id.id === selectedVenue?.id )).length
            const sendTimeChangedFromCustomTimeToNow = !isCustomTime && selectedMessage?.isCustomTime

   

            const configuration = getConfigurationForVenuesAndStatusesFilters({values})


            const shouldUpdateSendTime = () => {
              let shouldUpdateSendTime = selectedMessage?.sendTime === undefined || isCustomTime || sendTimeChangedFromCustomTimeToNow
              switch(selectedSendStatus) {
                case SEND_STATUS.DRAFT:
                  return shouldUpdateSendTime
                case SEND_STATUS.READY:
                  return shouldUpdateSendTime
                case SEND_STATUS.SENT:
                    shouldUpdateSendTime = selectedMessage?.sendTime === undefined 
                    return shouldUpdateSendTime
                default:
                  return shouldUpdateSendTime
              }
            }

            const sendTime = () => {
              if (isCustomTime) {
                return moment(values.startSendingTime).utc().format('YYYY-MM-DD HH:mm:ss')
              }
              
              return nowTime
            }

            let pushMessage: any = {
                "status": selectedSendStatus,
                "messageType": "PUSH",
                "isCustomTime": isCustomTime,
                "sentToMembersCount": sentToMembersCount,
                "payload": {
                    "message": values.message,
                    "images": {
                      mainImage: uploadedMessageImage
                    }
                },
                "id": values.id,
                "configuration": configuration
            }

            console.log('pushMessage', pushMessage)
            console.log('configuration', configuration)

            if (shouldUpdateSendTime()) {
              pushMessage.sendTime = sendTime()
            }

            const isNewMessage = history.location.state.messageId === 'NEW'

            if (
              !isNewMessage
            ) {
              pushMessage.id = selectedMessage?.id
              pushMessage.status = SEND_STATUS.READY
            }
            handleSaveAndPublish([pushMessage], isNewMessage)
        }}
      >
        {({dirty, errors, touched, values, handleChange, handleSubmit, handleBlur, resetForm, setFieldValue}) => (
          <Form>
            <Modal show={showRevertModal} centered onHide={() => {
              setShowRevertModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("manageVenuesScreen.revertChangesPopup.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("manageVenuesScreen.revertChangesPopup.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  resetForm()
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.confirm")}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showSaveModal} centered onHide={() => {
              setShowSaveModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("manageVenuesScreen.saveChangesPopup.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("manageVenuesScreen.saveChangesPopup.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowSaveModal(false)
                }}>
                  {t("manageVenuesScreen.saveChangesPopup.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  handleSubmit()
                  setShowSaveModal(false)
                }}>
                  {t("manageVenuesScreen.saveChangesPopup.confirm")}
                </Button>
              </Modal.Footer>
            </Modal>

            <Row>
              <Col md={4}>
                <Button
                  variant="secondary"
                  className="font-weight-bold"
                  onClick={() => onBackToVenues(dirty)}>
                  <FontAwesomeIcon className="mr-2" icon={["fas", "reply-all"]}/>
                  {t("sendPushNotificationsScreen.backToMessages")}
                </Button>
              </Col>
              <Col md={8}>

              </Col>

            </Row>
            <fieldset disabled={isEditingDisabled}>
            <h4 className="text-primary text-center mt-4 font-weight-bold">{t("sendPushNotificationsScreen.title")}</h4>

            <p className="text-center">{t("sendPushNotificationsScreen.description")}</p>

            <Container className="formContainer">

            {/* <Row>
                <Col md={4}></Col>
                <Col md={4}>
                  <Field
                    name="toMembersInVenue"
                    render={() => (
                      <FormGroup controlId="toMembersInVenue">
                        <FormLabel
                          className={`textInputLabel text-primary font-weight-bold d-block text-center ${errors.toMembersInVenue && touched.toMembersInVenue ? 'text-danger' : ''}`}>{t("sendPushNotificationsScreen.toMembersIn")}</FormLabel>
                        <FormControl className="textInput font-weight-bold" as='select'
                                     isInvalid={errors.toMembersInVenue && touched.toMembersInVenue ? true : false} value={values.toMembersInVenue}
                                     onChange={handleChange}>
                          {venuesNames.map(venueName => <option>{venueName?.trim()}</option>)}
                        </FormControl>
                        {errors.toMembersInVenue && touched.toMembersInVenue ? (
                          <small className="text-danger">{errors.toMembersInVenue}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
                <Col md={4}></Col>
            </Row> */}

              <AudienceCount/>
              <VenuesSelectionDropdown values={values} setFieldValue={setFieldValue} />
   

        
              <StatusesSelectionDropdown values={values} setFieldValue={setFieldValue}/>
         

          
            
            

                {Boolean(!isSingleVenue || Boolean(getActiveMemberStatusesAndSubscriptions({values})?.length)) &&

                  <div className="breakLine mb-4"/>
                  }
            

              <Row>
                <Col md={2}></Col>
                <Col md={8}>
                  <Field
                    name="message"
                    render={() => (
                      <FormGroup controlId="message">
                        <FormLabel
                          className={`textInputLabel text-primary font-weight-bold d-block text-center ${errors.message && touched.message ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.description`)}</FormLabel>
                        <FormControl
                          className="textInput"
                          as='textarea'
                          rows={8}
                          placeholder={t(`sendPushNotificationsScreen.text`)}
                          isInvalid={errors.message && touched.message ? true : false}
                          value={values.message ? values.message : undefined}
                          onChange={(e) => {
                            setFieldValue('message', e.target.value)
                          }}/>
                        {errors.message && touched.message ? (
                          <small className="text-danger">{errors.message}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
                <Col md={2}></Col>
              </Row>


              <div className="breakLine mt-4 mb-4"/>
         
              

              {(selectedMessage === undefined || selectedMessage.payload.images?.mainImage) &&
                <>
                  <Row>
                    <Col md={2}></Col>
                    <Col md={8} className="text-center">
                    <FormLabel
                          className={`textInputLabel text-primary font-weight-bold d-block text-center mb-0 `}>{t(`photo`)}</FormLabel>
                                     <div className="mb-3">
                                    <small>{t('productModifyScreen.productPhotoDescription')}</small>
                                  </div>
                      <MessageImage/>

                        {selectedMessage === undefined &&
                          <Field
                            name="imageURL"
                            render={() => (
                              <FormGroup controlId="imageURL" className="text-center mt-3">
                                <FormLabel
                                  className={`textInputLabel ${errors.imageURL && touched.imageURL ? 'text-danger' : ''}`}>
                                    {/* {t(`manageVenuesScreen.logo`)} */}
                                  </FormLabel>
                                
                       
                                <label className="btn btn-secondary">
                                  {t(`manageVenuesScreen.uploadPhoto`)}
                                  <input
                                    id="imageURL"
                                    name="file"
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={(event) => {
                                      if (event.currentTarget && event.currentTarget.files && event.currentTarget.files[0]?.type.includes("image")) {
                                        setFieldValue("imageURL", URL.createObjectURL(event.currentTarget.files[0]));
                                        setShowUploadImagePhotoModal(true)
                                      }
                                    }}/>
                                </label>
                              </FormGroup>
                            )}
                          />
                        }
                    </Col>
                    <Col md={2}>
                      
                    </Col>
                  </Row>

                  <div className="breakLine mt-4 mb-4"/>
                </>
              }

              <ImageUploadModal
                show={showUploadLImagePhotoModal}
                imageURL={values.imageURL ? values.imageURL : ""}
                aspectRatio={1500 / 900}
                cropShape="rect"
                onCroppedImage={(croppedImage) => {
                  setCroppedImage(croppedImage)
                }}
                onCancel={() => {
                  setShowUploadImagePhotoModal(false)
                }}
                onSave={() => {
                  setShowUploadImagePhotoModal(false)
                  handleUploadImage(croppedImage)
                }}/>


              <Row className="justify-content-center">
                <Col md={2}></Col>
                <Col md={6} >
                  <Field
                    name="startSendingTime"
                    render={() => (
                      <FormGroup controlId="startSendingTime">
                        <FormLabel
                          className={`textInputLabel text-primary font-weight-bold d-block text-center ${errors.startSendingTime && touched.startSendingTime ? 'text-danger' : ''}`}>{t("sendPushNotificationsScreen.startSending")}</FormLabel>
                        <FormControl className="textInput" as='select'
                                     style={{width: 250, margin: '0px auto'}}
                                     isInvalid={errors.startSendingTime && touched.startSendingTime ? true : false}
                                     defaultValue={isCustomTime ? t("sendPushNotificationsScreen.customTime") : t("sendPushNotificationsScreen.now")}
                                     onChange={(e) => {
                                        if (e.target.value === t("sendPushNotificationsScreen.now")) {
                                            setIsCustomTime(false)
                                        } else {
                                            setIsCustomTime(true)
                                            setFieldValue(
                                                'startSendingTime', 
                                                `${moment(values.startSendingTime).tz(formatTimezone).format('YYYY-MM-DD')} 23:59`
                                            )
                                        }
                                      }}>
                          {[t("sendPushNotificationsScreen.now"), t("sendPushNotificationsScreen.customTime")].map(optionName => <option>{optionName}</option>)}
                        </FormControl>
                        {errors.startSendingTime && touched.startSendingTime ? (
                          <small className="text-danger">{errors.startSendingTime}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />

                    {isCustomTime &&
                        <div style={{
                          textAlign: 'center',
                          marginTop: 10
                        }}>
                            <DatePicker 
                            // @ts-ignore
                            selected={values.startSendingTime ? new Date(values.startSendingTime) : undefined}
                            onChange={(date) => {
                              setFieldValue('startSendingTime', moment(`${moment(date).tz(formatTimezone).format('YYYY-MM-DD')} ${moment(date).tz(formatTimezone,true).format('HH:mm')}`).toISOString())

                            }}
                            // @ts-ignore
                            // minDate={new Date(values.payments.validFrom)}
                            className="datePicker pl-3 pt-1 pb-1 mr-3"
                            disabled={isEditingDisabled}
                            dateFormat="P"
                            locale={i18n.languages[0]}/>

                        <div className="d-inline-block" style={{position: 'relative'}}>
                            <input className="textInputLabel datePicker pl-2 pr-1" 
                            defaultValue={moment(values.startSendingTime).format('HH:mm')}
                            onChange={(e) => {
                              setFieldValue(
                                'startSendingTime',
                                moment(`${moment(values.startSendingTime).tz(formatTimezone).format('YYYY-MM-DD')} ${e.target.value}`).tz(
                                  formatTimezone,
                                  true).toISOString()
                              )
                            }
                            }
                            style={{height: 34}} 
                            type="time" id="appt" name="appt" min="00:00" max="24:00" required></input>
                        </div>
                      </div>
                    }
                    
                </Col>
                <Col md={2}>
                </Col>
            </Row>

            </Container>

            {/* {userSessionStore!.isSuperAdmin &&
                <Button variant="secondary mt-4 mr-2" style={{float: 'right'}} onClick={() => {
                  setSelectedSendStatus(SEND_STATUS.SENT)
                  setShowSaveModal(true)
                }} className="font-weight-bold">
                    {isCustomTime ? t("sendPushNotificationsScreen.markAsScheduled") : t("messagesScreen.markAsSent")}
                </Button>
              } */}

           

{!isMessageSent &&
    <Button variant="primary mt-4 mr-2"  disabled={!Boolean(values.message)}  style={{float: 'right'}} onClick={() => {
      setSelectedSendStatus(SEND_STATUS.READY)
      setShowSaveModal(true)
    }} className="font-weight-bold">
      {isCustomTime ? 
        <FontAwesomeIcon className="mr-2" icon={["fas", "clock"]}/>
        :
        <FontAwesomeIcon className="mr-2" icon={["fal", "mobile"]}/>
      }
      
      {isCustomTime ? t("sendPushNotificationsScreen.scheduleMessage") : t("sendPushNotificationsScreen.sendMessage")}
  </Button>
}
          

          {!Boolean(selectedMessage?.status === SEND_STATUS.SENT) &&
          <Button variant="secondary mt-4 mr-2"   disabled={!Boolean(values.message)} style={{float: 'right'}} onClick={() => {
            setSelectedSendStatus(SEND_STATUS.DRAFT)
            setShowSaveModal(true)
          }} className="font-weight-bold">
            <FontAwesomeIcon className="mr-2" icon={["fas", "reply-all"]}/>
            {t("sendPushNotificationsScreen.saveDraft")}
          </Button>
          }
           

        <CancelScheduledMessageButton/>
    

            <div style={{height: 40}}></div>

           </fieldset>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default withTranslation()(inject("userSessionStore", "api", "messageStore", "memberStore")(observer(SendPushNotificationScreen)))
