import React, { SyntheticEvent, useEffect, useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import { DateRangePicker, createStaticRanges } from "react-date-range"
//import createStaticRanges from "react-date-range"
import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear
} from "date-fns"
import * as _ from "lodash"
import log from "loglevelnext"
import moment from "moment-timezone"
import Button from "react-bootstrap/Button"
import { enGB, fi } from "react-date-range/dist/locale"
import { useTranslation } from "react-i18next"
import { useUrlFilter } from "../hooks/FilterHooks"
import i18n from "../i18n"


interface SelectionRange {
  startDate: Date
  endDate: Date
  key: string
}

export interface Selection {
  selection: SelectionRange
}

const selectionRange: SelectionRange = {
  startDate: startOfDay(addMonths(new Date(), -12)),
  endDate: endOfDay(new Date()),
  key: "selection",
}


export interface OnSelectArgs {
  startDate: Date
  endDate: Date
}

type DateRangeDropdownProps = {
  onSelect?: (selection: OnSelectArgs | null) => void
  formatter?: (selection: Selection) =>  Selection
  timezone: string
}

const timeHelper = {
  startOf12Month: startOfDay(addMonths(new Date(), -12)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOf90Days: startOfDay(addDays(new Date(), -90)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1))
};

const DateRangePickerDropdownMenu = React.forwardRef(
  // @ts-ignore
  ({children, style, className, "aria-labelledby": labeledBy, onSelect, onChange, currentRange, onApply, onReset}, ref) => {
    // @FIXME: override broken react-bootrap transform.. remove when fixed..
    const {t} = useTranslation()

    const newStyle = {transform: "none", top: 40}
    return (
      // @ts-ignore
      <div ref={ref}
           style={{...style, ...newStyle}}
           className={className}
           aria-labelledby={labeledBy}>
        <DateRangePicker
          locale={i18n.languages[0] === "en" ? enGB : fi}
          ranges={[currentRange.selection]}
          moveRangeOnFirstSelection={false}
          showSelectionPreview={true}
          inputRanges={[]}
          onChange={onChange}
          maxDate={new Date()}
          rangeColors={["#1775F3"]}
          staticRanges={createStaticRanges(createStaticRanges([
            {
              label: t("last12Months"),
              range: () => ({
                startDate: timeHelper.startOf12Month,
                endDate: timeHelper.endOfToday,
              }),
            },
            {
              label: t("thisMonth"),
              range: () => ({
                startDate: timeHelper.startOfMonth,
                endDate: timeHelper.endOfMonth,
              }),
            },
            {
              label: t("lastMonth"),
              range: () => ({
                startDate: timeHelper.startOfLastMonth,
                endDate: timeHelper.endOfLastMonth,
              }),
            },
            {
              label:  t("last90Days"),
              range: () => ({
                startDate: timeHelper.startOf90Days,
                endDate: timeHelper.endOfToday,
              }),
            },
            {
              label: t("thisYear"),
              range: () => ({
                startDate: timeHelper.startOfYear,
                endDate: timeHelper.endOfYear,
              }),
            },
            {
              label: t("lastYear"),
              range: () => ({
                startDate: timeHelper.startOfLastYear,
                endDate: timeHelper.endOfLastYear,
              }),
            },
          ]))}
        />
        <div className="text-right px-3" style={{
          backgroundColor: 'transparent',
          paddingBottom: 10
        }}>
            <Button onClick={onReset} variant="outline-primary" className="mr-1">{t("dateRangeDropdown.reset")}</Button>
            <Button onClick={onApply}>{t("dateRangeDropdown.apply")}</Button>
        </div>
      </div>
    )
  })

export function DateRangeDropdown(props: DateRangeDropdownProps) {
  const {t} = useTranslation()
  const [currentRange, setCurrentRange] = useState({selection: selectionRange})
  const [isOpen, setIsOpen] = useState(false)
  const [isReset, setIsReset] = useState(true)
  const {urlFilter, setUrlFilter} = useUrlFilter("dateRange")
  const {onSelect} = props

  let selectedRange = urlFilter

  useEffect(() => {
    if (urlFilter) {
      // @ts-ignore
      const newCurrentRange = dateObjectify(selectedRange)
      if (!_.isEqual(currentRange, newCurrentRange)) {
        setCurrentRange(newCurrentRange)

        if (onSelect) {
          log.debug("DateRangeDropdown: useEffect, running onSelect function:", urlFilter)
          onSelect({
            // @ts-ignore
            startDate: urlFilter.selection.startDate,
            // @ts-ignore
            endDate: urlFilter.selection.endDate,
          })
        }
      }
      setIsReset(false)
    } else {
      setIsReset(true)
    }
    // eslint-disable-next-line
  }, [urlFilter])

  function dateObjectify(selection: Selection) {
    let startDate = new Date(selection.selection.startDate)
    let endDate = new Date(selection.selection.endDate)
    return {selection: {startDate: startDate, endDate: endDate, key: "selection"}}
  }

  function handleRangeChange(payload: Selection) {
    console.log(payload)
    setCurrentRange(props.formatter ? props.formatter(payload) : payload)
  }

  function handleApply() {
    if (currentRange !== selectedRange) {
      setUrlFilter({
        selection: {
          startDate: currentRange.selection.startDate,
          endDate: currentRange.selection.endDate,
          key: "selection",
        },
      })
    }
    if (props.onSelect) {
      log.debug("DateRangeDropdown: handleApply, running onSelect function:", currentRange.selection)
      props.onSelect({
        startDate: currentRange.selection.startDate,
        endDate: currentRange.selection.endDate,
      })
    }
    setIsReset(false)
    setIsOpen(false)
  }

  function handleReset() {
    setIsReset(true)
    setIsOpen(false)
    setUrlFilter(undefined)
    setCurrentRange({selection: selectionRange})

    if (props.onSelect) {
      props.onSelect(null)
    }
  }
  // @ts-ignore
  const startDateFormatted = moment.utc(selectedRange && selectedRange.selection.startDate).tz(props.timezone).format("ll")
  // @ts-ignore
  const endDateFormatted = moment.utc(selectedRange && selectedRange.selection.endDate).tz(props.timezone).format("ll")

  return (
    <>
    <Dropdown
      show={isOpen}
      /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
      onClick={(e: any) => e.stopPropagation()}
      // @ts-ignore
      onToggle={(
        isOpenToggled: boolean,
        event: SyntheticEvent,
        metadata: {
          source: 'select' | 'click' | 'rootClose' | 'keydown'
        }
      ) => {
        setIsOpen(isOpenToggled)
        if (isOpenToggled) {
          // @ts-ignore
          if (selectedRange) setCurrentRange(dateObjectify(selectedRange))
        }
      }}>
      <Dropdown.Toggle
        variant="secondary"
        id="dropdown-custom-1">
        {isReset ?
          t("dateRangeDropdown.noFilterSet") :
          startDateFormatted + " - " + endDateFormatted
        }
      </Dropdown.Toggle>
      <Dropdown.Menu
        // @ts-ignore
        currentRange={currentRange}
        // @ts-ignore
        onChange={handleRangeChange}
        // @ts-ignore
        onApply={handleApply}
        // @ts-ignore
        onReset={handleReset}
        as={DateRangePickerDropdownMenu}/>
    </Dropdown>
    </>
  )
}
