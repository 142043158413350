import { IVenue } from "../Venue"

export interface LocalizedField {
  fieldName: string 
  countryCode: string 
}

export enum COUNTRY_CODES {
    FINLAND = 'fi',
    MALTA = 'mt'
}

export enum COUNTRY_NAMES {
    FINLAND = 'finland',
    MALTA = 'malta'
}

export const getMembershipStatusCountrySpecificFields = ({venueCountryName}:{ venueCountryName: string}): LocalizedField[] => {
    const countryName = venueCountryName?.toLowerCase()

    if (countryName === COUNTRY_NAMES.FINLAND) {
      return [
        { fieldName: 'title', countryCode: COUNTRY_CODES.FINLAND },
        { fieldName: 'description', countryCode: COUNTRY_CODES.FINLAND }
      ];
    }
    return [];
  }
  
  export const getBenefitCountrySpecificFields = ({venueCountryName}:{ venueCountryName: string}): LocalizedField[] => {
    const countryName = venueCountryName?.toLowerCase()

    if (countryName === COUNTRY_NAMES.FINLAND) {
      return [
        { fieldName: 'title', countryCode: COUNTRY_CODES.FINLAND }
      ];
    }
    return [];
  }

  export const getProductCountrySpecificFields = ({venueCountryName}: {venueCountryName: string}): LocalizedField[] => {
    const countryName = venueCountryName?.toLowerCase()
  
    if (countryName === COUNTRY_NAMES.FINLAND) {
      return [
        { fieldName: 'title', countryCode: COUNTRY_CODES.FINLAND },
        { fieldName: 'description', countryCode: COUNTRY_CODES.FINLAND }
      ];
    }
    return [];
  }

  export const getMembershipStatusSubscriptionCountrySpecificFields = ({venueCountryName}:{ venueCountryName: string}): LocalizedField[] => {
    const countryName = venueCountryName?.toLowerCase()

    if (countryName === COUNTRY_NAMES.FINLAND) {
      return [{ fieldName: 'title', countryCode: COUNTRY_CODES.FINLAND },
        { fieldName: 'description', countryCode: COUNTRY_CODES.FINLAND }
      ] ;
      
    }
    return [];
  }