import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { observer } from "mobx-react"
import moment from "moment"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { useTranslation } from "react-i18next"
import { IVenueStatusSubscription, IVenueStatusSubscriptionReport, VENUE_STATUS_THEME, VENUE_STATUS_TYPE } from "../models/MemberStore"
import { PAYMENT_PERIOD } from "../screens/CreateUpdateMemberStatusSubscriptionScreen/CreateUpdateMemberStatusSubscriptionScreen"
import { colors } from "../theme/colors"
import { centsToFullCurrencyUnits } from "../utils"

export interface StatusSubscriptionSummaryCardProps {
  onEdit: () => void
  memberStatus: IVenueStatusSubscription
  report?: IVenueStatusSubscriptionReport
}

export const StatusSubscriptionSummaryCard = observer((props: StatusSubscriptionSummaryCardProps) => {

  const {memberStatus, report, onEdit} = props

  const {t} = useTranslation()


  const statusThemeColor = () => {
    if (memberStatus.style?.theme === VENUE_STATUS_THEME.GOLD) {
      return t("manageMembersCardScreen.gold")
    } else if (memberStatus.style?.theme === VENUE_STATUS_THEME.BLACK) {
      return t("manageMembersCardScreen.black")
    } else {
      return ''
    }
  }

  const getValidityInMonths = (days: number) => {
    switch(days) {
      case 30:
        return `1 ${t("manageMembersCardScreen.month")}`
      case 90:
        return `3 ${t("manageMembersCardScreen.months")}`
      case 180:
          return `6 ${t("manageMembersCardScreen.months")}`
      case 365:
        return `12 ${t("manageMembersCardScreen.months")}`
      case 730:
        return `24 ${t("manageMembersCardScreen.months")}`
      default:
        return ''
    }
  }

  const statusPrice = memberStatus.type === VENUE_STATUS_TYPE.SOLD ? `${centsToFullCurrencyUnits(memberStatus.payments?.price?.amount)}€` : t("manageMembersCardScreen.free")
  const getDateRange = (date1: string, date2: string) => {
    return `${moment(new Date(date1)).format('YYYY-MM-DD')} - ${moment(new Date(date2)).format('YYYY-MM-DD')}`
  }
  const totalSalesRevenue = `${centsToFullCurrencyUnits(report?.revenueTotal)} €`

  // const validity = memberStatus.payments.type === MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FIXED 
  //   ? getDateRange(memberStatus.payments.validFrom, memberStatus.payments.validThrough) 
  //   : getValidityInMonths(memberStatus.payments.duration)

  
  const renewalPaymentPeriod = () => {
    if (memberStatus.payments.period === PAYMENT_PERIOD.MONTH) {
      return t("manageMembersStatusSubscriptionsScreen.renewalMonthly")
    }

    if (memberStatus.payments.period === PAYMENT_PERIOD.YEAR) {
      return t("manageMembersStatusSubscriptionsScreen.renewalAnnually")
    }

    return ''
  }
  
  return (
    <Card className="mb-3 bg-white">
        <Card.Body className="p-2 m-0">
          <Container>
            <Row>
              <Col className="col-5 py-2 d-flex align-items-center justify-content-start">
                <div>
                  <h5
                    className="pl-1 m-0 font-heading font-weight-bold">{memberStatus?.localeTitle}</h5>
                  <p className="pl-1 m-0 mt-1">{memberStatus?.localeShortDescription}</p>
          
                  <p className="pl-1 m-0 mt-1 text-primary d-inline-block font-size-small">{t("manageMembersCardScreen.price")}: {statusPrice}</p>
            
                  
                  <p className="text-green ml-5 m-0 mt-1 d-inline-block font-size-small">{renewalPaymentPeriod()}</p>


                {/* TODO: add when we have this data */}
                {/* <a target="_blank" className="ml-3 font-size-small" rel="noopener noreferrer" href=""><FontAwesomeIcon className="mr-1" icon={["fal", "link"]}/>{t("manageMembersStatusSubscriptionsScreen.linkToSubscription")}</a> */}
                </div>
              </Col>
              <Col className="col-2 d-flex align-items-center justify-content-end">
                {memberStatus?.images?.mainImage &&
                  <img 
                    alt="membershipCard"
                    src={memberStatus?.images?.mainImage} 
                    style={{width: 100, height: 60, objectFit: 'cover', borderRadius: '5px'}}/>
                }
              </Col>

              <Col className="col-4 d-flex align-items-center justify-content-center">
                <div className="mb-0 font-weight-normal" style={{color: colors.secondaryText, fontSize: 12}}><h5 style={{fontWeight: 'bold'}} className="text-center text-primary mb-0">{report?.activeMembers || '0'}</h5>{t("manageMembersStatusSubscriptionsScreen.active")}</div>
                <div className="mb-0 font-weight-normal" style={{color: colors.secondaryText, fontSize: 12, marginLeft: 15}}><h5 style={{fontWeight: 'bold'}} className="text-center text-primary mb-0">{report?.inactiveMembers || '0'}</h5>{t("manageMembersStatusSubscriptionsScreen.inactive")}</div>
                <div className="mb-0 font-weight-normal" style={{color: colors.secondaryText, fontSize: 12, marginLeft: 15}}><h5 style={{fontWeight: 'bold'}} className="text-center text-primary mb-0">{totalSalesRevenue}</h5>{t("manageMembersStatusSubscriptionsScreen.totalSales")}</div>
              </Col>
              
              <Col className="col-1 py-2 d-flex align-items-center justify-content-end">
                <Button
                  variant={"transparent"}
                  onClick={onEdit}
                >
                  <FontAwesomeIcon className="m-0 mr-2" icon={["fal", "pen-to-square"]}/>
                  {t("manageMembersCardScreen.edit")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Body>
    </Card>
  )
})
