import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import React, {useEffect} from "react"
import {useFilters, useSortBy, useTable} from "react-table"
import moment from "moment-timezone"
import Card from "react-bootstrap/Card"
import Select from "react-select"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Nav from "react-bootstrap/Nav"
import {useTranslation} from "react-i18next"
import {useUrlFilter} from "../hooks/FilterHooks"
import * as _ from "lodash"
import {toJS} from "mobx"
import Button from "react-bootstrap/Button"
import {IEventStore} from "../models/EventStore"
import {observer} from "mobx-react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Badge from "react-bootstrap/Badge"
import {IVenue} from "../models/Venue";
import Modal from 'react-bootstrap/Modal'
import { useState } from "react"
import { IEvent } from "../models/Event"
import { truncateText } from "../utils"
import { EventLocation } from "../Locations"

// @ts-ignore
function DateCell(cellData) {
  const value = cellData.cell.value
  return value ? moment.utc(value).tz("UTC").format("DD.MM.YYYY HH:mm") : "-"
}

// @ts-ignore
function RenderEventCard({
  row,
  t,
  isOrganizationContext,
  forwardToModify,
  cloneEvent,
  navigateToEvent,
}:{
  row: any, 
  t: any, 
  isOrganizationContext: boolean, 
  forwardToModify: (venue: IVenue, eventId: string, createBasedOn: string) => void, 
  cloneEvent: (event: IEvent) => void, 
  navigateToEvent: ({venue, eventId}: {venue: IVenue, eventId: string}) => void}) {
  const id = row.original.eventId.id
  const startTimeMoment = moment.utc(row.values.startTime).tz(row.original.eventId.restaurantId.timezone)
  const endTimeMoment = moment.utc(row.values.endTime).tz(row.original.eventId.restaurantId.timezone)
  const status = row.original.eventId.isPaymentDisabled() ? "paymentDisabled"
    : row.original.eventId.isHidden() ? "hidden"
      : row.original.eventId.isSoldOut() ? "soldOut"
        : row.original.eventId.isFree() ? "free" : ""
  const pricing = toJS(row.original.eventId.pricing)
  const startTimeFormatted = startTimeMoment.format("HH:mm")
  const endTimeFormatted = endTimeMoment.format("HH:mm")
  const hasGuestList = row.values.visitorCount > 0;

  return (
    <Card className={"mt-4 " + (status === "hidden" ? "bg-disabled" : "")} key={id}>
      <Card.Body>
        <Container>
          <Row>
            <Col
              className="d-flex text-center col-2 flex-column align-items-center justify-content-center text-primary">
              <div
                className="m-0 p-0 font-heading font-weight-bold font-size-medium">{startTimeMoment.format("ddd DD.MM")}</div>
              <div className="m-0 p-0">{startTimeFormatted}-{endTimeFormatted}</div>
            </Col>
            <Col className="col-3 d-flex flex-row align-items-center justify-content-start">
              <div className="m-0 p-0 d-flex flex-column align-items-start justify-content-center">
                <div
                   onClick={hasGuestList ? () => navigateToEvent({ venue: row.original.eventId.restaurantId, eventId: row.original.eventId.id }) : undefined}
                   style={{ cursor: hasGuestList ? 'pointer' : 'default' }}
                   className={
                    "pr-3 font-heading font-size-medium font-weight-bold" +
                    (status === "hidden" ? " font-italic" : "") +
                    (hasGuestList ? " hover-underline" : "")
                  }>
                  {truncateText(row.values.title, 22)}
                </div>
                <div>
                  {status !== "" && status !== "soldOut" && status !== "hidden" ?
                    <span>{t("event.status." + status)}</span> : null
                  }
                  {status !== "paymentDisabled" && _.map(pricing, (item: any, key: string) => {
                    return (
                      <span className="mr-2"
                            key={id + key}>{t("eventScreen.tableHeaders." + key)} {item.amount / 100} {t("currencies.EUR")}</span>
                    )
                  })}
                  {status !== "paymentDisabled" && row.original.eventId.hasTicketQuota() &&
                  <span
                      className="ml-2 text-primary">{t("eventScreen.maxVisitors", { count: row.original.eventId.getTicketQuota()})}</span>
                  }
                </div>
                
                <div className="font-size-small mt-1">
                  <a target="_blank" rel="noopener noreferrer" href={row.values.publicUrl}><FontAwesomeIcon className={"mr-2"} icon={["fal", "link"]}/>{t("event.publicUrl")}</a>
                </div>
                <div className="mt-1">
                  {status === "soldOut" ? <div className="ml-4 font-size-medium"><Badge className=""
                                                                                    variant="danger">{t("event.status.soldOut")}</Badge>
                  </div> : null}
                </div>
              </div>
              {status === "hidden" ?
                <div className="ml-4 text-menugray font-size-medium"><FontAwesomeIcon size="1x" className="mr-2"
                                                                                      icon={["fal", "eye-slash"]}/>
                </div> : null}
            </Col>
            <Col className="d-flex text-center col-2 flex-row align-items-center justify-content-end">
              {row.original.eventId.images.mainImage &&
                <img src={row.original.eventId.images.mainImage} style={{width: 125, height: 75, objectFit: 'cover', borderRadius: '5px', marginRight: 24}}/>
              }
            </Col>
            <Col className="d-flex text-center col-5 flex-row align-items-center justify-content-end">
              <Container>
                <Row>
                  {/* <Col
                    className="d-flex text-center col-2 flex-column align-items-start justify-content-center font-size-medium text-menugray">
                    {isOrganizationContext ? row.values.venue : null}
                  </Col> */}
                  <Col className="d-flex text-center col-3 flex-column align-items-center justify-content-center">
                      <div style={{visibility: row.original.cloakroomCount > 0 ? 'initial' : 'hidden'}}>
                        <div style={{bottom: 0, right: 16, position: 'absolute'}}>
                          <div className="text-primary font-heading font-size-larger">{row.original.cloakroomCount}</div>
                          <div style={{fontSize: 14}} className="text-menugray">{t("eventScreen.tableHeaders.cloakroom")}</div>
                        </div>
                      </div>
                  </Col>
                  <Col className="d-flex text-center col-2 flex-column align-items-center justify-content-center">
                      <div className="align-items-center">
                        <div style={{bottom: 0, left: 0, position: 'absolute'}}>
                          <div className="text-primary font-heading font-size-larger">{row.original.entranceCount}</div>
                          <div style={{fontSize: 14}} className="text-menugray">{t("eventScreen.tableHeaders.entrance")}</div>
                        </div>
                      </div>
                  </Col>
                  <Col className="d-flex text-center col-3 flex-column align-items-center justify-content-center">
                    <div className="m-0 p-0 d-flex text-center flex-column align-items-center justify-content-center">
                      {status === "" || row.values.visitorCount > 0 ?
                        <>
                          <div
                            className="text-primary font-heading font-size-larger">{row.values.totalSalesAmount / 100} {t("currencies.EUR")}</div>
                          <div className="text-menugray">{t("eventScreen.tableHeaders.totalAmount")}</div>
                          {/* row.values.sales.map((item: any) => {
                    return (
                      <small
                        key={id + item.item}>({t("eventScreen.tableHeaders." + item.item)} {item.subtotal.amount / 100} {t("currencies." + item.subtotal.currency)})</small>
                    )
                  }) */}
                        </>
                        : null
                      }
                    </div>
                  </Col>
                  <Col className="col-4 d-flex align-items-center justify-content-end">
                    <Button className="text-menugray"
                            onClick={() => cloneEvent(row.original.eventId)}
                            variant="link"><FontAwesomeIcon icon={["fal", "clone"]}/>
                    </Button>
                    <Button className="text-menugray mr-4"
                            onClick={() => forwardToModify(row.original.eventId.restaurantId, id, 'false')}
                            variant="link"><FontAwesomeIcon icon={["fal", "pen-to-square"]}/>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  )
}

// @ts-ignore
function RenderEventTable({columns, data, desc, isOrganizationContext, forwardToModify, navigateToEvent}) {
  const {t} = useTranslation()
  const [showCopyEventModal, setShowCopyEventModal] = useState(false)
  const [eventToCopy, setEventToCopy] = useState<IEvent | undefined>(undefined)
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 20,
    }),
    [],
  )

  const {
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [{id: "startTime", desc: desc}],
      },
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
  )

  const cloneEvent = (event: IEvent) => {
    setEventToCopy(event)
    setShowCopyEventModal(true)
  }

  const CopyEventModal = () => 
    <Modal show={showCopyEventModal} centered onHide={() => {
      setShowCopyEventModal(false)
    }}>
      <Modal.Header closeButton>
        <Modal.Title>{t("eventScreen.modals.copyEvent.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("eventScreen.modals.copyEvent.description")}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={() => {
          setShowCopyEventModal(false)
        }}>
          {t("eventScreen.modals.copyEvent.cancel")}
        </Button>
        <Button variant="primary" onClick={() => {
          forwardToModify(eventToCopy?.restaurantId, eventToCopy?.id, 'true')
        }}>
          {t("eventScreen.modals.copyEvent.confirm")}
        </Button>
      </Modal.Footer>
    </Modal>

  // @ts-ignore
  return (
    <>
      <CopyEventModal/>
      {rows.length > 0 ? rows.map(
        (row, i) => {
          prepareRow(row)
          return RenderEventCard({row, t, isOrganizationContext, forwardToModify, cloneEvent, navigateToEvent})
        }) : <p className="mt-3 font-size-medium">{t("eventScreen.noEvents")}</p>}
    </>
  )
}

// @ts-ignore
function VenueMultiselectFilter({column: {filterValue, setFilter, preFilteredRows, id}}) {
  const {t} = useTranslation()
  const {urlFilter, setUrlFilter} = useUrlFilter("venues")
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id])
    })
    // @ts-ignore
    return [...options.values()]
  }, [id, preFilteredRows])
  const mappedOptions = options.map((o, i) => {
    return {id: i, value: o, label: o}
  })
  let multiSelectValue = mappedOptions.filter((option) =>
      // @ts-ignore
    urlFilter  && urlFilter.indexOf(option.value) !== -1,
  )
  useEffect(() => {
    if (urlFilter) {
      setFilter(urlFilter)
    }
  }, [urlFilter, setFilter])

  return options.length > 1 && (
    <Col>
      <div>
        <Select
          placeholder={t("eventScreen.filters.selectVenues")}
          onChange={(entry: any) => {
            const values = entry ? entry.map((o: any) => {
              return o.value
            }) : undefined
            setFilter(values)
            setUrlFilter(values)
          }}
          isMulti={true}
          options={mappedOptions}
          value={urlFilter ? multiSelectValue || [] : []}
        />
      </div>
    </Col>
  )
}


type EventTableProps = {
  data: any
  isOrganizationContext: boolean
  eventStore: IEventStore,
  forwardToModify: (venue: IVenue, eventId: string) => void
  navigateToEvent: ({venue, eventId}: {venue: IVenue, eventId: string}) => void
}

const EventTable = observer((props: EventTableProps) => {
  const {t} = useTranslation()
  const {urlFilter, setUrlFilter} = useUrlFilter("tab")



  useEffect(() => {

  }, [urlFilter])
  const columns = React.useMemo(
    () => [
      {
        Header: t("eventScreen.tableHeaders.title"),
        accessor: (row: any) => row.eventId.title,
        sortType: "basic",
        id: "title",
        width: 350,
      },
      {
        Header: t("eventScreen.tableHeaders.venue"),
        accessor: (row: any) => row.eventId.restaurantId.name,
        sortType: "basic",
        Filter: VenueMultiselectFilter,
        filter: "includes",
        id: "venue",
      },
      {
        Header: t("eventScreen.tableHeaders.status"),
        id: "status",
        accessor: (row: any) => row.eventId.status,
      },
      {
        Header: t("eventScreen.tableHeaders.startTime"),
        id: "startTime",
        accessor: (row: any) => row.eventId.startTime,
        sortType: "basic",
        Cell: DateCell,
      },
      {
        Header: t("eventScreen.tableHeaders.endTime"),
        id: "endTime",
        accessor: (row: any) => row.eventId.endTime,
        sortType: "basic",
        Cell: DateCell,
      },
      {
        Header: t("eventScreen.tableHeaders.visitors"),
        id: "visitorCount",
        accessor: (row: any) => row.visitorCount,
        sortType: "basic",
      },
      {
        Header: t("eventScreen.tableHeaders.totalAmount"),
        id: "totalSalesAmount",
        accessor: (row: any) => row.totalSalesAmount,
        sortType: "basic",
      },
      {
        Header: t("eventScreen.tableHeaders.publicUrl"),
        accessor: (row: any) => row.eventId._computed_publicUrls.get("fi"),
        sortType: "basic",
        id: "publicUrl",
        width: 350,
      },
    ],
    [t],
  )

  function handleOnSelect(item: string) {
    setUrlFilter(item)
  }

  function getSelectedTable({
    selected,
    forwardToModify,
    navigateToEvent
  }: {
    selected: string | undefined, forwardToModify: (venue: IVenue, eventId: string) => void, navigateToEvent: ({venue, eventId}: {venue: IVenue, eventId: string}) => void}) {
    if (!selected || selected === "spotlight") {
      return (
        <>
          <h4 className="mt-4 text-primary">{t("eventScreen.upcomingEvents")}</h4>
          <RenderEventTable columns={columns} data={props.data.filteredReportSpotlight.upcoming} desc={false}
                            isOrganizationContext={props.isOrganizationContext} forwardToModify={forwardToModify} navigateToEvent={navigateToEvent} />
          <h4 className="mt-4 text-primary">{t("eventScreen.pastEvents")}</h4>
          <RenderEventTable columns={columns} data={props.data.filteredReportSpotlight.past} desc={true}
                            isOrganizationContext={props.isOrganizationContext} forwardToModify={forwardToModify} navigateToEvent={navigateToEvent}/>
        </>
      )
    }
    if (selected === "upcoming") {
      return (
        <>
          <h4 className="mt-4 text-primary">{t("eventScreen.upcomingEvents")}</h4>
          <RenderEventTable columns={columns} data={props.data.filteredReportUpcoming} desc={false}
                            isOrganizationContext={props.isOrganizationContext} forwardToModify={forwardToModify} navigateToEvent={navigateToEvent}/>
          {props.eventStore.hasMoreUpcoming() ?
            <div className="mt-4 text-center"><Button variant="secondary" size="sm" onClick={() => {
              props.eventStore.fetchUpcoming()
            }}>
              <FontAwesomeIcon className="mr-2" icon={["fal", "chevrons-down"]}/>
              {t("eventScreen.loadMore")}
            </Button></div> : null}
        </>
      )
    }
    if (selected === "past") {
      return (
        <>
          <h4 className="mt-4 text-primary">{t("eventScreen.pastEvents")}</h4>
          <RenderEventTable columns={columns} data={props.data.filteredReportPast} desc={true}
                            isOrganizationContext={props.isOrganizationContext} forwardToModify={forwardToModify} navigateToEvent={navigateToEvent}/>
          {props.eventStore.hasMorePast() ?
            <div className="mt-4 text-center"><Button variant="secondary" size="sm" onClick={() => {
              props.eventStore.fetchPast()
            }}>
              <FontAwesomeIcon className="mr-2" icon={["fal", "chevrons-down"]}/>
              {t("eventScreen.loadMore")}
            </Button></div> : null}
        </>)
    }
  }
  

  return (
    <Container className="p-0 m-0">
      <Row>
        <Col>
          {/*
            // @ts-ignore */}
          <Nav variant="tabs" className="nav-tabs-transparent" activeKey={urlFilter || "spotlight"} onSelect={handleOnSelect}>
            <Nav.Item>
              <Nav.Link eventKey="spotlight"><FontAwesomeIcon className="mr-2"
                                                              icon={["fal", "calendar-star"]}/>{t("eventScreen.spotlight")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="upcoming"><FontAwesomeIcon className="mr-2"
                                                             icon={["fal", "calendar-lines"]}/>{t("eventScreen.upcomingEvents")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="past"><FontAwesomeIcon className="mr-2"
                                                         icon={["fal", "calendar-check"]}/>{t("eventScreen.pastEvents")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col>
          {getSelectedTable({
            selected: urlFilter,
            forwardToModify: props.forwardToModify,
            navigateToEvent: props.navigateToEvent
          })}
        </Col>
      </Row>
    </Container>
  )
})
export default EventTable
